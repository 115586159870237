<template>
  <div class="sub-container">
    <v-card-actions>
      <span class="mt-n3 ml-3 head-sub-text">All Campaigns</span>
      <v-spacer />

      <v-btn
        color="blue"
        variant="flat"
        class="text-none ml-6 mt-4"
        size="small"
        prepend-icon="mdi-plus"
        :to="{ name: 'AddCampaign' }"
      >
        <span class="btn-text">Add Campaign</span>
      </v-btn>
    </v-card-actions>

    <v-card-actions class="test-field">
      <div class="test-field px-2">
        <p>Start Date</p>
        <input class="date-field" type="date" v-model="formattedStartDate" />
      </div>
      <div class="test-field px-2">
        <p>End Date</p>
        <input class="date-field" type="date" v-model="formattedEndDate" />
      </div>

      <v-btn
        color="red"
        variant="flat"
        class="text-none ml-6 mt-4"
        size="small"
        prepend-icon="mdi-magnify"
        @click="submitFilters"
      >
        <span class="btn-text">Search</span>
      </v-btn>

      <v-btn
        color="teal"
        variant="flat"
        class="text-none mt-4"
        prepend-icon="mdi-delete-empty"
        size="small"
        @click="clearFilters"
      >
        <span class="btn-text">Clear Filters</span>
      </v-btn>
      <v-spacer />
      <div class="search-field mt-5">
        <v-text-field
          v-model="search"
          label="Quick Search"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details
          single-line
        />
      </div>
    </v-card-actions>

    <v-card-actions class="ml-8">
      <v-spacer />
    </v-card-actions>
    <v-card variant="flat" class="ml-3">
      <v-data-table
        :items="campaigns.length > 0 ? campaigns : []"
        :headers="headers"
        item-value="id"
        :search="search"
      >
        <template #[`item.date`]="{ item }">
          {{
            item.selectable.created_at
              ? formatTime(item.selectable.created_at)
              : "N/A"
          }}
        </template>
        <template #[`item.sent_at`]="{ item }">
          {{
            item.selectable.sent_at
              ? formatTime(item.selectable.sent_at)
              : "N/A"
          }}
        </template>

        <template #[`item.estatus`]="{ item }">
          <v-chip
            :color="getColor(item.selectable.is_active)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_active ? "active" : "inactive"
            }}</span>
          </v-chip>
        </template>
        <template #[`item.status`]="{ item }">
          <v-chip
            :color="getStatusColor(item.selectable.status)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{ item.selectable.status }}</span>
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props"> mdi-dots-vertical</v-icon>
            </template>

            <v-list>
              <v-list-item
                class="mt-n3"
                :to="{
                  name: 'AddCampaign',
                  params: { id: item.selectable.id },
                }"
              >
                <v-list-item-title class="list-title">Edit</v-list-item-title>
              </v-list-item>
              <v-list-item @click="exportToExcel(item.selectable)">
                <v-list-item-title class="list-title"
                  >Export to Excel</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
  <v-dialog v-model="loaderState" max-width="500">
    <v-card>
      <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
      <v-card-actions
        ><v-spacer />
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>

        <v-spacer />
      </v-card-actions>

      <v-progress-linear
        class="mx-2 mt-n3"
        color="teal"
        indeterminate
      ></v-progress-linear>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as XLSX from "xlsx";
export default {
  name: "AllCampaigns",
  beforeRouteEnter(to, from, next) {
    next((v) => {

      v.$store.dispatch("campaign/getCampaigns",v.formData);
    });
  },

  data() {
    return {
      selectedEndDate: null,
      selectedStartDate: null,
      formData: {
        start_date: null,
        end_date: null,
      },
      groups: [],
      menu: false,
      menu2: false,
      menu3: false,
      search: "",
      isValid: false,
      loadingState: false,
      screenSize: "",
      oldValue: false,

      selected: [],

      headers: [
        { title: "date", key: "date" },
        { title: "sent_at", key: "sent_at" },
        { title: "Subject", key: "subject" },
        { title: "email_status", key: "estatus" },
        { title: "delivery_status", key: "status" },
        { title: "No. Sent", key: "number_sent" },
        { title: "No. Bounced", key: "number_bounced" },
        { title: "No. Clicked", key: "number_clicked" },
        { title: "No. Opened", key: "number_opened" },

        { title: "Actions", key: "actions" },
      ],
    };
  },

  computed: {
    campaigns() {
      return this.$store.getters["campaign/campaignGetters"]("campaigns");
    },

    loaderState() {
      return this.$store.getters["campaign/campaignGetters"]("loaderState");
    },
    formattedStartDate: {
      get() {
        return this.selectedStartDate;
      },
      set(value) {
        this.selectedStartDate = this.formatDate(value);
      },
    },
    formattedEndDate: {
      get() {
        return this.selectedEndDate;
      },
      set(value) {
        this.selectedEndDate = this.formatDate(value);
      },
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    // this.getCurrentDate();
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    deleteItem(val) {
      alert("Are you sure you want to delete template ?");
      this.$store.dispatch("campaign/deleteCampaign", parseInt(val));
    },

    getColor(val) {
      if (val) return "#46c35f";
      else return "orange";
    },
    getStatusColor(val) {
      if (val === "draft") return "orange";
      else return "green";
    },
    changeAvatarColor(item) {
      // Change the color of the clicked avatar
      item.avatarProps.color = "red"; // Change to desired color
    },
    exportToExcel(val) {
      // Assuming `val` is a single campaign object
      const data = [
        {
          subject: val.subject,
          name: val.name,
          is_active: val.is_active ? "active" : "inactive",
          status: val.status,
          number_sent: val.number_sent,
          number_bounced: val.number_bounced,
          number_clicked: val.number_clicked,
          number_opened: val.number_opened,
        },
      ];

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Campaigns");

      XLSX.writeFile(wb, `${data[0].subject}.xlsx`);
    },
    formatTime(val) {
      // Assuming `val` is a single campaign object
      const formattedDateTime = new Date(val)
        .toISOString()
        .replace("T", " ")
        .split(".")[0];

      return formattedDateTime;
    },
    submitFilters() {
      // Reset page to 1

      (this.formData.start_date = this.formattedStartDate),
        (this.formData.end_date = this.formattedEndDate),
        // Dispatch the cleaned data
        this.$store.dispatch("dashboard/getStats", this.formData);
    },
    clearFilters() {
      // Get current date
      this.formattedStartDate = null;
      this.formattedEndDate = null;
    },
    getCurrentDate() {
      const now = new Date();

      // First day of the month (YYYY-MM-DD format)
      const firstDay = `${now.getFullYear()}-${String(
        now.getMonth() + 1
      ).padStart(2, "0")}-01`;

      // Last day of the month (YYYY-MM-DD format)
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      const formattedLastDay = `${lastDay.getFullYear()}-${String(
        lastDay.getMonth() + 1
      ).padStart(2, "0")}-${String(lastDay.getDate()).padStart(2, "0")}`;
      this.selectedStartDate = firstDay;
      this.selectedEndDate = formattedLastDay;
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style>
@import url("../campaignStyle.css");
</style>
