<template>
  <div class="sub-container">
    <v-card-actions>
      <span class="mt-n3 ml-6 head-sub-text">Emails by Campaign</span>
      <v-spacer />
      <v-btn
        color="#50568e"
        variant="flat"
        class="text-none"
        size="small"
        :to="{ name: 'emailsByCampaign' }"
      >
        <span class="btn-text"> View Emails Per Campaign</span>
      </v-btn>
    </v-card-actions>

    <v-card-actions class="">
      <div class="search-field px-2">
        <p class="mt-n2">Recipient</p>

        <v-text-field
          class="mx-2 my-2"
          v-model="formData.recipients"
          label="Search"
          variant="outlined"
          density="compact"
          hide-details
          style="color: #3c3731"
        />
        <!-- <v-text-field
          v-model="search"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details
          single-line
        /> -->
      </div>
      <div class="d-flex justify-space-between">
        <!-- <p>Sent</p> -->
        <div class="px-3">
          <v-chip
            size="small"
            :color="showSent ? 'green' : ''"
            :prepend-icon="showSent ? 'mdi-check' : ''"
            @click="addFilter('sent')"
          >
            Sent
          </v-chip>
        </div>

        <div class="px-2">
          <v-chip
            size="small"
            :color="showOp ? 'green' : ''"
            :prepend-icon="showOp ? 'mdi-check' : ''"
            @click="addFilter('opened')"
          >
            Opened
          </v-chip>
        </div>
        <div class="px-2">
          <v-chip
            size="small"
            :color="showDel ? 'green' : ''"
            :prepend-icon="showDel ? 'mdi-check' : ''"
            @click="addFilter('delivered')"
          >
            Delivered
          </v-chip>
        </div>
        <div class="px-2">
          <v-chip
            size="small"
            :color="showclicked ? 'green' : ''"
            :prepend-icon="showclicked ? 'mdi-check' : ''"
            @click="addFilter('clicked')"
          >
            Clicked
          </v-chip>
        </div>
      </div>
      <v-spacer />

      <v-btn
        color="red"
        variant="flat"
        prepend-icon="mdi-magnify"
        class="text-none"
        size="small"
        @click="submitFilters"
      >
        <span class="btn-text">Search</span>
      </v-btn>

      <v-btn
        color="teal"
        variant="flat"
        class="text-none"
        prepend-icon="mdi-delete-empty"
        size="small"
        @click="clearFilters"
      >
        <span class="btn-text">Clear Filters</span>
      </v-btn>
      <v-spacer />
    </v-card-actions>

    <v-card variant="flat" class="ml-3">
      <v-data-table
        :items="singleEmails.length > 0 ? singleEmails : []"
        :headers="headers"
        item-value="id"
        :search="search"
      >
        <template #[`item.is_sent`]="{ item }">
          <v-chip
            :color="getColor(item.selectable.is_sent)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_sent ? "sent" : "not sent"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.is_clicked`]="{ item }">
          <v-chip
            :color="getColor(item.selectable.is_clicked)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_clicked ? "clicked" : "not clicked"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.is_opened`]="{ item }">
          <v-chip
            :color="getColor(item.selectable.is_opened)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_opened ? "opened" : "unopened"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.is_delivered`]="{ item }">
          <v-chip
            :color="getColor(item.selectable.is_delivered)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_delivered ? "delivered" : "undelivered"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props"> mdi-dots-vertical</v-icon>
            </template>

            <v-list>
              <v-list-item
                class="mt-n3"
                :to="{ name: 'AddList', params: { id: item.selectable.id } }"
              >
                <v-list-item-title class="list-title">Edit</v-list-item-title>
              </v-list-item>

              <v-list-item
                class="mt-n3"
                @click="deleteItem(item.selectable.id)"
              >
                <v-list-item-title class="list-title">Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="loaderState" max-width="500">
      <v-card>
        <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
        <v-card-actions
          ><v-spacer />
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>

          <v-spacer />
        </v-card-actions>

        <v-progress-linear
          class="mx-2 mt-n3"
          color="teal"
          indeterminate
        ></v-progress-linear>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
export default {
  name: "singleCampaignPage",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (v.$route.params.id !== "") {
        const data = {
          mail_campaign_id: v.$route.params.id,
        };
        v.$store.dispatch("email/getFilteredEmails", data);
      }
    });
  },

  data() {
    return {
      showSent: false,
      showOp: false,
      showDel: false,
      showclicked: false,
      offset: 0,
      formData: {
        is_sent: false,
        is_clicked: false,
        is_delivered: false,
        is_opened: false,
        to_email: null,
        mail_campaign_id: this.$route.params.id,
        page: null,
      },
      dialog: true,
      groups: [],
      menu: false,
      menu2: false,
      menu3: false,
      search: "",
      isValid: false,
      loadingState: false,
      screenSize: "",
      oldValue: false,
      statuses: ["delivered", "bounced"],

      selected: [],
      selectStatus: 0,

      headers: [
        {
          title: "sent_at",
          key: "updated_at",
        },
        {
          title: "Campaign",
          key: "campaign_name",
        },
        { title: "Recipient", key: "to_email" },

        {
          title: "Sent",
          key: "is_sent",
        },
        {
          title: "Opened",
          key: "is_opened",
        },
        {
          title: "Delieverd",
          key: "is_delivered",
        },
        {
          title: "Clicked",
          key: "is_clicked",
        },
        // {
        //     title: "Date",
        //     key: "created_at",

        // },
        // { title: "Actions", key: "actions" },
      ],
    };
  },

  computed: {
    singleEmails() {
      return this.$store.getters["email/emailGetters"]("emails");
    },

    loaderState() {
      return this.$store.getters["email/emailGetters"]("loaderState");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    deleteItem(val) {
      alert("Are you sure you want to delete list ?");
      this.$store.dispatch("list/deleteList", parseInt(val));
    },

    getColor(val) {
      if (val) return "#46c35f";
      else return "orange";
    },
    changeAvatarColor(item) {
      // Change the color of the clicked avatar
      item.avatarProps.color = "red"; // Change to desired color
    },

    filterEmails(val) {
      if (val === "Delivered") {
        this.$store.dispatch("email/getEmailsbyStatus", {
          id: parseInt(this.$route.params.id),
          status: "delivered",
        });
      } else {
        if (val === "Bounced") {
          this.$store.dispatch("email/getEmailsbyStatus", {
            id: parseInt(this.$route.params.id),
            status: "bounced",
          });
        } else {
          this.$store.dispatch(
            "email/getSingleEmails",
            parseInt(this.$route.params.id)
          );
        }
      }
    },

    exportAllToExcel() {
      // Create a new array with only the fields you want
      const cleanedData = this.singleEmails.map((email) => {
        // Return an object with only the fields you want to include
        return {
          email: email.to_email,
        };
      });

      // Use the cleaned data to create the Excel sheet
      const ws = XLSX.utils.json_to_sheet(cleanedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Campaigns");

      // Determine the file name based on the selected status
      XLSX.writeFile(wb, `${this.singleEmails[0].campaign_name}.xlsx`);
    },

    submitFilters() {
      // Reset page to 1
      this.formData.page = 1;

      // Create a clean object with only the necessary fields
      const cleanData = {
        mail_campaign_id: this.formData.mail_campaign_id,
      };

      // Only add true boolean values
      if (this.formData.is_sent) cleanData.is_sent = true;
      if (this.formData.is_clicked) cleanData.is_clicked = true;
      if (this.formData.is_delivered) cleanData.is_delivered = true;
      if (this.formData.is_opened) cleanData.is_opened = true;

      // Only add non-null values
      if (this.formData.to_email) cleanData.to_email = this.formData.to_email;
      if (this.formData.page) cleanData.page = this.formData.page;

      // Dispatch the cleaned data
      this.$store.dispatch("email/getFilteredEmails", cleanData);
    },
    addFilter(filterType) {
      // Toggle the show state for the selected filter
      if (filterType === "sent") {
        this.showSent = !this.showSent;
        this.formData.is_sent = this.showSent; // Set to the current toggle state
      } else if (filterType === "opened") {
        this.showOp = !this.showOp;
        this.formData.is_opened = this.showOp; // Set to the current toggle state
      } else if (filterType === "delivered") {
        this.showDel = !this.showDel;
        this.formData.is_delivered = this.showDel; // Set to the current toggle state
      } else if (filterType === "clicked") {
        this.showclicked = !this.showclicked;
        this.formData.is_clicked = this.showclicked; // Set to the current toggle state
      }
    },
    clearFilters(){
      this.showSent = false;
      this.showOp = false;
      this.showDel = false;
      this.showclicked = false;
      this.formData = {
        is_sent: false,
        is_clicked: false,
        is_delivered: false,
        is_opened: false,
        to_email: null,
        mail_campaign_id: this.$route.params.id,
        page: null,
      };
      this.$store.dispatch("email/getSingleEmails", parseInt(this.$route.params.id));
    }
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style>
@import url("../emailStyle.css");
</style>
