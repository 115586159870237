export default {
    // stats: "api/mail-campaign-stats/?summary=true",
    prof:"api/me/",
    filterActivity: (data) => {
      let url = `api/logs/stats/?start_date=${data.start_date}&end_date=${data.end_date}&page=${data.page}`;
      
      return url;
    },
    filterUsers: (data) => {
      let url = `api/all-users/list/?limit=10&offset=${data.offset}`;
      return url;
    },
    singleUser: (data) => {
      let url = `api/users/list/?id=${data}`;
      return url;
    },
    stats: (data) => {
      let url = `api/mail-campaign-stats/?summary=true&start_date=${data.start_date}&end_date=${data.end_date}`;
      return url;
    }
  
  };
  