<template>
  <div class="sub-container">
    <v-card-actions>
      <span class="mt-n3 ml-6 head-sub-text">All Lists</span>
      <v-spacer />

      <v-btn color="#50568e" variant="flat" class="text-none" size="small" :to="{ name: 'AddList' }">
        <span class="btn-text">Add List</span>
      </v-btn>
    </v-card-actions>

    <v-card-actions class="ml-8">
      <!-- <div class="text-center">
        <v-menu v-model="menu" :close-on-content-click="false" location="end">
          <template v-slot:activator="{ props }">
            <v-chip v-bind="props"> Created </v-chip>
          </template>

<v-card min-width="300">
  Residuals
  <v-card-actions>
    <v-spacer></v-spacer>

    <v-btn variant="text" @click="menu = false"> Cancel </v-btn>
    <v-btn color="primary" variant="text" @click="menu = false">
      Save
    </v-btn>
  </v-card-actions>
</v-card>
</v-menu>
</div>
<div class="text-center">
  <v-menu v-model="menu2" :close-on-content-click="false" location="end">
    <template v-slot:activator="{ props }">
            <v-chip v-bind="props" class="ml-2"> Type </v-chip>
          </template>

    <v-card min-width="300">
      <v-card-actions>
        <v-checkbox color="indigo-darken-3" label="Dynamic" class="status-check"></v-checkbox>
        <v-checkbox label="Imported" color="indigo-darken-3" class="status-check"></v-checkbox></v-card-actions>
      <v-divider class="mt-n6" />

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="#50568e" variant="text" @click="menu2 = false" class="text-none" size="small">
          <span class="menu-btn-text">Clear</span>
        </v-btn>
        <v-btn color="#50568e" variant="flat" @click="menu2 = false" class="text-none" size="small">
          <span class="btn-text">Apply</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</div> -->

      <v-spacer />
      <div class="search-field">
        <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined"
          density="compact" hide-details single-line />
      </div>
    </v-card-actions>
    <v-card variant="flat"  class="ml-3">
      <v-data-table :items="lists.length > 0 ? lists : []" :headers="headers" item-value="id"
        :search="search">
        <!-- <template #[`item.status`]="{ item }">
                    <v-chip :color="getColor(item.selectable.is_subscribed)" variant="flat" size="x-small">
                        <span class="chip-text">{{
                            item.selectable.is_subscribed ? "subscribed" : "unsubscribed"
                            }}</span>
                    </v-chip>
                </template> -->

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props"> mdi-dots-vertical</v-icon>
            </template>

            <v-list>
              <v-list-item class="mt-n3" :to="{ name: 'AddList', params: { id: item.selectable.id } }">
                <v-list-item-title class="list-title">Edit</v-list-item-title>
              </v-list-item>

              <v-list-item class="mt-n3" @click="deleteItem(item.selectable.id)">
                <v-list-item-title class="list-title">Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="loaderState" max-width="500">
      <v-card>

        <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
        <v-card-actions><v-spacer />
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>

          <v-spacer />
        </v-card-actions>


        <v-progress-linear class="mx-2 mt-n3" color="teal" indeterminate></v-progress-linear>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ListDisplayPage",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("subscribers/getSubscribers");
      v.$store.dispatch("subscribers/getLists");
    });
  },

  data() {
    return {
      dialog: true,
      groups: [],
      menu: false,
      menu2: false,
      menu3: false,
      search: "",
      isValid: false,
      loadingState: false,
      screenSize: "",
      oldValue: false,

      selected: [],

      headers: [
        {
          title: "Name",
          key: "name",
        },
        { title: "No. of Subscribers", key: "num_subscribers" },
        // {
        //     title: "Type",
        //     key: "status",

        // },
        // {
        //     title: "No of Subscribers",
        //     key: "status",

        // },
        { title: "Actions", key: "actions" },
      ],
    };
  },

  computed: {
    lists() {
      return this.$store.getters["subscribers/subscribersGetters"]("Lists");
    },
    subscribers() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "subscribers"
      );
    },
    loaderState() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "loaderState"
      );
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    deleteItem(val) {
      alert("Are you sure you want to delete list ?");
      this.$store.dispatch("list/deleteList", parseInt(val));
    },

    getColor(val) {
      if (val) return "#46c35f";
      else return "orange";
    },
    changeAvatarColor(item) {
      // Change the color of the clicked avatar
      item.avatarProps.color = "red"; // Change to desired color
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style>
@import url("../listStyles.css");
</style>
