import call from "@/service/http";
import constants from "./templatesConstants";
import eventBus from "@/utils/eventBus";

const templatesModule = {
  namespaced: true,
  state: {
    Templates:[],
    loaderState: false,
    template:{}
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    templatesGetters: (state) => (val) => state[val],
  },
  actions: {
    saveTemplate: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
    
      call("post", constants.saveTemplate, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          commit("MUTATE", { state: "loaderState", value: false });
    
          if (res.data?.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message || "Template saved successfully!",
            });
    
            // Optional: Emit success event if needed
            // eventBus.emit("creationSuccess");
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message || "Failed to save template.",
            });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
    
          eventBus.emit("showToast", {
            type: "error",
            message: err.response?.data?.message || "An error occurred while saving the template.",
          });
    
          console.error("Error saving template:", err);
        });
    },
    
    getTemplates: ({ commit }) => {
      call("get", constants.saveTemplate)
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", { state: "Templates", value: res.data.data });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Error fetching Lists",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSingleTemplate: ({ commit }, data) => {
      call("get", constants.singleTemplate(data))
        .then((res) => {
      
          if (res.data.status_code === 200) {
            commit("MUTATE", { state: "template", value: res.data.data });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Error fetching Lists",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteTemplate: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("delete", constants.delTemp(data), data)
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });
            window.location.reload()
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
            window.location.reload()
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
    editTemplate: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("patch", constants.delTemp(data.id), data.alldata)
        .then((res) => {
          if (res.status === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: "Edited Successfully",
            });
            commit("MUTATE", { state: "loaderState", value: false });
            // eventBus.emit("subAdded");
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Failed to edit subscriber",
            });
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
  },
};

export default templatesModule;
