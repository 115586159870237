import call from "@/service/http";
import constants from "./rolesConstants";
import eventBus from "@/utils/eventBus";

const rolesModule = {
  namespaced: true,
  state: {
    loaderState: false,
    singleList: {},
    companies: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    rolesGetters: (state) => (val) => state[val],
  },
  actions: {
    getCompanies: ({ commit }) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.companies)
        .then((res) => {
          if (res.status === 200) {
            commit("MUTATE", { state: "companies", value: res.data });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Error fetching companies",
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveCompany: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("post", constants.companies, data)
        .then((res) => {
          if (res.status === 201) {
            eventBus.emit("showToast", {
              type: "success",
              message: "Sucessfully saved company",
            });

            eventBus.emit("redirectToList");
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Failed to save Company",
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
    sendInvite: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("post", constants.invite, data)
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: "Sucessfully sent invite",
            });
            commit("MUTATE", { state: "loaderState", value: false });
            // window.location.reload();
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Failed to save Company",
            });
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
  },
};

export default rolesModule;
