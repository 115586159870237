<template>
  <div class="sub-container">
    <v-card-actions>
      <span class="mt-n3 ml-3 head-sub-text">All Subscribers</span>
      <v-spacer />
      <v-btn
        v-if="screenSize === 'Large'"
        color="#38a4f8"
        variant="flat"
        class="text-none"
        size="small"
        :to="{
          name: 'importSubs',
        }"
      >
        <span class="btn-text">Import subscribers</span>
      </v-btn>
      <v-btn
        color="#50568e"
        variant="flat"
        class="text-none"
        size="small"
        @click="redirectToAdd()"
      >
        <span class="btn-text">Add Subscriber</span>
      </v-btn>
    </v-card-actions>

    <v-card-actions class="ml-n3">
      <div class="search-field">
        <p class="mt-n2 ml-6">Groups</p>
        <v-autocomplete
          v-model="formData.group_id"
          :items="lists.length > 0 ? lists : []"
          class="mx-6 mt-3"
          color="blue-grey-lighten-2"
          item-title="name"
          item-value="id"
          label="Select"
          chips
          closable-chips
          density="compact"
          style="color: #3c3731"
          variant="outlined"
        ></v-autocomplete>
      </div>

      <div class="search-field px-2">
        <p class="mt-n2">Recipient</p>

        <v-text-field
          class="mx-2 my-2"
          v-model="formData.email"
          label="Search"
          variant="outlined"
          density="compact"
          hide-details
          style="color: #3c3731"
        />
      </div>

      <div class="search-test-field">
        <v-card-actions>
          <!-- Properly spaced and formatted checkboxes -->
          <v-checkbox
            color="indigo-darken-3"
            label="Subscribed"
            class="status-check m-4"
            v-model="formData.is_subscribed"
          ></v-checkbox>

          <v-checkbox
            v-model="formData.unsubscribed"
            label="Unsubscribed"
            color="indigo-darken-3"
            class="status-check mr-4"
          ></v-checkbox>

          <v-checkbox
            color="indigo-darken-3"
            label="Blacklisted"
            class="status-check"
            v-model="formData.is_blacklisted"
          ></v-checkbox>
        </v-card-actions>
      </div>

      <v-spacer />

      <div class="search-field mt-n7">
        <v-text-field
          v-model="search"
          label=" Quick search"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details
          single-line
        />
      </div>
    </v-card-actions>

    <v-card-actions class="ml-6">
      <v-btn
        color="#50568e"
        variant="flat"
        class="text-none"
        size="small"
        @click="deleteMultiple()"
      >
        <span class="btn-text">Delete Multiple</span> </v-btn
      ><v-spacer />

      <div class="search-field ml-6 mt-n6">
        <v-btn
          color="red"
          variant="flat"
          class="text-none mr-2"
          size="small"
          prepend-icon="mdi-magnify"
          @click="submitFilters()"
        >
          <span class="btn-text">Search</span>
        </v-btn>

        <v-btn
          color="teal"
          variant="flat"
          class="text-none"
          prepend-icon="mdi-delete-empty"
          size="small"
          @click="clearFilters"
        >
          <span class="btn-text">Clear Filters</span>
        </v-btn>
      </div>
      <v-spacer />
    </v-card-actions>

    <v-card variant="flat" class="ml-3 custom-card">
      <v-data-table
        v-model="selectedTable"
        show-select
        :items="subscribers ? subscribers : []"
        :headers="headers"
        item-value="id"
        :search="search"
       
      >
        <template #[`item.fullName`]="{ item }">
          <a :href="'/user/' + item.id"
            >{{ item.first_name }} {{ item.last_name }}</a
          >
        </template>
        <template #[`item.status`]="{ item }">
          <v-chip
            :color="getColor(item.selectable.is_subscribed)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_subscribed ? "subscribed" : "unsubscribed"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.blacklist`]="{ item }">
          <v-chip
            v-if="item.selectable.is_blacklisted"
            :color="getBlackColor(item.selectable.is_blacklisted)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_blacklisted ? "blacklisted" : ""
            }}</span>
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props"> mdi-dots-vertical</v-icon>
            </template>

            <v-list>
              <!-- <v-list-item>
                <v-list-item-title class="list-title"
                  >Add to blacklist</v-list-item-title
                >
              </v-list-item> -->
              <v-list-item
                class="mt-n3"
                :to="{
                  name: 'AddSubscriber',
                  params: { id: item.selectable.id },
                }"
              >
                <v-list-item-title class="list-title">Edit</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.selectable.is_subscribed"
                class="mt-n3"
                @click="unSubscribe(item.selectable.id)"
              >
                <v-list-item-title class="list-title"
                  >unsubscribe</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                v-if="!item.selectable.is_subscribed"
                class="mt-n3"
                @click="Subscribe(item.selectable.id)"
              >
                <v-list-item-title class="list-title"
                  >Subscribe</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                v-if="!item.selectable.is_blacklisted"
                class="mt-n3"
                @click="Blacklist(item.selectable.id)"
              >
                <v-list-item-title class="list-title"
                  >Blacklist</v-list-item-title
                >
              </v-list-item>
              <v-list-item class="mt-n3" @click="deleteSub(item.selectable.id)">
                <v-list-item-title class="list-title">Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-card-actions class="mt-n12">
        <v-spacer class="" />

        <v-btn
          color="#38a4f8"
          variant="flat"
          class="text-none"
          size="small"
          @click="onPaginationNextChange()"
        >
          <span class="btn-text">Prev</span>
        </v-btn>
        <div class="ml-n4"></div>

        <v-btn
          color="green"
          variant="flat"
          class="text-none"
          size="small"
          @click="onPaginationChange()"
        >
          <span class="btn-text">Next</span>
        </v-btn>
        <div class="ml-12"></div>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="loaderState" max-width="500">
      <v-card>
        <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
        <v-card-actions
          ><v-spacer />
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>

          <v-spacer />
        </v-card-actions>

        <v-progress-linear
          class="mx-2 mt-n3"
          color="teal"
          indeterminate
        ></v-progress-linear>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>

    <div class="text-center pa-4">
      <v-dialog
        v-model="deleteDialog"
        max-width="400"
        persistent
        transition="fade-transition"
      >
        <v-card class="rounded-lg" elevation="8">
          <v-card-title class="text-h5 font-weight-bold d-flex align-center">
            <v-icon start color="error" size="large" class="me-2">
              mdi-alert-circle
            </v-icon>
            Warning
          </v-card-title>

          <v-card-text class="text-body-1 py-4">
            Are you sure you want to delete this item?
          </v-card-text>

          <v-card-actions class="pa-4 pt-0">
            <v-btn
              color="error"
              variant="outlined"
              @click="deleteDialog = false"
              class="text-none font-weight-medium"
            >
              Cancel
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              color="success"
              variant="elevated"
              @click="deleteMultiple2()"
              class="text-none font-weight-medium"
            >
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubListPage",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("subscribers/getFilteredSubscribers", v.formData);
      v.$store.dispatch("subscribers/getLists");
    });
  },

  data() {
    return {
      formData: {
        is_subscribed: false,
        unsubscribed: false,
        is_blacklisted: false,
        email: null,
        group_id: null,
        offset: null,
      },
      show: false,
      showSent: false,
      options: {
        // page: 1,
        // itemsPerPage: 10,
        // Add other options as needed (sortBy, etc.)
      },
      deleteDialog: false,
   
      totalSubscribers: 0,
      selectedTable: [],
      selectedGroup: null,
      subStatus: null,
      menu: false,
      menu2: false,
      menu3: false,
      search: "",
      isValid: false,
      loadingState: false,
      screenSize: "",
      oldValue: false,
      buttonPress: false,

      selected: [],

      headers: [
        { title: "Created At", key: "created_at" },
        { title: "Email", key: "email" },
        {
          title: "Status",
          key: "status",
          value: (item) => `${item.is_subscribed}`,
        },
        {
          title: "Name",
          key: "fullName",
          value: (item) => `${item.first_name} ${item.last_name}`,
        },

        {
          title: "BlackList",
          key: "blacklist",
          value: (item) => `${item.is_blacklisted}`,
        },
        { title: "Group name", key: "group_name" },
        { title: "Actions", key: "actions" },
      ],

      loading: false,
      totalPages: null,
      showButton: false,

      page: 1,
      itemPage: 0,
      currentValue: 0,
      currentPage: 0,
      changePage: false,
    };
  },

  computed: {
    lists() {
      return this.$store.getters["subscribers/subscribersGetters"]("Lists");
    },
    subscribers() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "subscribers"
      );
    },
    // filteredSubs() {
    //   return this.$store.getters["subscribers/subscribersGetters"](
    //     "filteredSubs"
    //   );
    // },
    loaderState() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "loaderState"
      );
    },

    next() {
      return this.$store.getters["subscribers/subscribersGetters"]("next");
    },
    previous() {
      return this.$store.getters["subscribers/subscribersGetters"]("previous");
    },
    limit() {
      return this.$store.getters["subscribers/subscribersGetters"]("limit");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    showFilter() {
      this.showSent = !this.showSent;
    },

    showStatus() {
      this.show = !this.show;
    },
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    deleteSub(val) {
      alert("Are you sure you want to delete subscriber ?");
      this.$store.dispatch("subscribers/deleteSubscriber", parseInt(val));
    },

    unSubscribe(val) {
      alert("Are you sure you want to unsubscribe subscriber ?");
      const data = {
        subdata: {
          is_subscribed: false,
        },
        id: parseInt(val),
      };
      this.$store.dispatch("subscribers/unsubSubscriber", data);
    },
    Subscribe(val) {
      alert("Are you sure you want to resubscribe subscriber ?");
      const data = {
        subdata: {
          is_subscribed: true,
        },
        id: parseInt(val),
      };
      this.$store.dispatch("subscribers/unsubSubscriber", data);
    },
    Blacklist(val) {
      alert("Are you sure you want to blacklist subscriber ?");
      const data = {
        subdata: {
          is_blacklisted: true,
        },
        id: parseInt(val),
      };
      this.$store.dispatch("subscribers/unsubSubscriber", data);
    },

    getColor(val) {
      if (val) return "#46c35f";
      else return "orange";
    },
    getBlackColor(val) {
      if (val) return "black";
      else return "";
    },
    changeAvatarColor(item) {
      // Change the color of the clicked avatar
      item.avatarProps.color = "red"; // Change to desired color
    },
    redirectToAdd() {
      this.$router.push({ name: "AddSubscriber" });
    },

    deleteMultiple() {
      this.deleteDialog = true;
    },

    deleteMultiple2() {
      const data = {
        ids: [...this.selectedTable],
      };
      this.$store.dispatch("subscribers/deleteMultiple", data);
    },

    updateShowButton() {
      if (
        this.currentValue === this.currentPage &&
        this.currentValue !== 0 &&
        this.currentPage !== 0
      ) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
    loadMoreSubs() {
      this.itemPage = 0;
      this.page = 1;


      this.$store.dispatch("subscribers/moreSubs", this.next);
   
    },
    onPaginationChange() {
      this.formData.offset += 10;
      this.$store.dispatch("subscribers/getFilteredSubscribers", this.formData);
    },

    onPaginationNextChange() {
      // Update the offset state

      this.formData.offset -= 10;
      this.$store.dispatch("subscribers/getFilteredSubscribers", this.formData);
    },

    submitFilters() {
      // Reset page to 1
      this.formData.offset = null;
      // console.log(this.formData);
      this.$store.dispatch("subscribers/getFilteredSubscribers", this.formData);
    },

    clearFilters() {
      this.formData = {
        is_subscribed: false,
        unsubscribed: false,
        is_blacklisted: false,
        email: null,
        group_id: null,
        offset: null,
      };
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style>
@import url("../subscriberStyle.css");
</style>
