import stats from "../dashboard/components/stats";
import dashboard from "../dashboard/views/dashboard";
import userActivity from "../dashboard/components/userActivity";
import allusers from "../dashboard/components/allusers";
import edituser from "../dashboard/components/edit-user.vue";

const dashboardRoutes = [
  {
    path: "/dashboard",
    component: dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "stats",
        name: "Dashboard",
        component: stats,
        meta: { requiresAuth: true },
      },
      {
        path: "user-activity",
        name: "userActivity",
        component: userActivity,
        meta: { requiresAuth: true },
      },

      {
        path: "all-users",
        name: "allusers",
        component: allusers,
        meta: { requiresAuth: true },
      },
      {
        path: "add-users/:id",
        name: "edituser",
        component: edituser,
        meta: { requiresAuth: true },
      },
    ],
  },
];

export default dashboardRoutes;
