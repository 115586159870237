<template>
  <div class="sub-container">
    <v-card-actions>
      <span class="mt-n3 ml-6 head-sub-text">User Activity</span>
      <v-spacer />
    </v-card-actions>

    <v-card-actions class="test-field">
      <div class="test-field px-2">
        <p>Start Date</p>
        <input class="date-field" type="date" v-model="formattedStartDate" />
      </div>
      <div class="test-field px-2">
        <p>End Date</p>
        <input class="date-field" type="date" v-model="formattedEndDate" />
      </div>

      <v-spacer />

      <v-btn color="red" variant="flat" class="text-none" size="small" prepend-icon="mdi-magnify"
        @click="submitFilters">
        <span class="btn-text">Search</span>
      </v-btn>

      <v-btn color="teal" variant="flat" class="text-none" prepend-icon="mdi-delete-empty" size="small"
        @click="clearFilters">
        <span class="btn-text">Clear Filters</span>
      </v-btn>
      <v-spacer />
    </v-card-actions>

    <v-card variant="flat" class="ml-3">
      <v-data-table :items="emails.length > 0 ? emails : []" :headers="headers" item-value="id" :search="search"
        :hide-default-footer="false">
        <template #[`item.campaign_name`]="{ item }">
          {{
            item.selectable.campaign_name !== undefined
              ? item.selectable.campaign_name
              : "Test "
          }}
        </template>

        <template #[`item.is_sent`]="{ item }">
          <v-chip :color="getColor(item.selectable.is_sent)" variant="flat" size="x-small">
            <span class="chip-text">{{
              item.selectable.is_sent ? "sent" : "not sent"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.is_clicked`]="{ item }">
          <v-chip :color="getColor(item.selectable.is_clicked)" variant="flat" size="x-small">
            <span class="chip-text">{{
              item.selectable.is_clicked ? "clicked" : "not clicked"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.is_opened`]="{ item }">
          <v-chip :color="getColor(item.selectable.is_opened)" variant="flat" size="x-small">
            <span class="chip-text">{{
              item.selectable.is_opened ? "opened" : "unopened"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.is_delivered`]="{ item }">
          <v-chip :color="getColor(item.selectable.is_delivered)" variant="flat" size="x-small">
            <span class="chip-text">{{
              item.selectable.is_delivered ? "delivered" : "undelivered"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props"> mdi-dots-vertical</v-icon>
            </template>

            <v-list>
              <v-list-item class="mt-n3" :to="{ name: 'AddList', params: { id: item.selectable.id } }">
                <v-list-item-title class="list-title">Edit</v-list-item-title>
              </v-list-item>

              <v-list-item class="mt-n3" @click="deleteItem(item.selectable.id)">
                <v-list-item-title class="list-title">Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-card-actions class="mt-n12">
        <v-spacer class="" />

        <v-btn color="#38a4f8" variant="flat" class="text-none" size="small" @click="onPaginationNextChange">
          <span class="btn-text">Prev</span>
        </v-btn>
        <div class="ml-n4"></div>

        <v-btn color="green" variant="flat" class="text-none" size="small" @click="onPaginationChange">
          <span class="btn-text">Next</span>
        </v-btn>
        <div class="ml-12"></div>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="loaderState" max-width="500">
      <v-card>
        <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
        <v-card-actions><v-spacer />
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>

          <v-spacer />
        </v-card-actions>

        <v-progress-linear class="mx-2 mt-n3" color="teal" indeterminate></v-progress-linear>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "userActivity",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      // Get current date
      const now = new Date();

      // First day of the month (YYYY-MM-DD format)
      const firstDay = `${now.getFullYear()}-${String(
        now.getMonth() + 1
      ).padStart(2, "0")}-01`;

      // Last day of the month (YYYY-MM-DD format)
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      const formattedLastDay = `${lastDay.getFullYear()}-${String(
        lastDay.getMonth() + 1
      ).padStart(2, "0")}-${String(lastDay.getDate()).padStart(2, "0")}`;

      const data = {
        start_date: firstDay,
        end_date: formattedLastDay,
        page: 1,
      };

      v.$store.dispatch("dashboard/getActivity", data);
    });
  },

  data() {
    return {
      //   menu: false,
      selectedEndDate: null,
      selectedStartDate: null,
      date: null,

      offset: 0,
      dialog: true,
      groups: [],
      menu: false,
      menu2: false,
      menu3: false,
      search: "",
      isValid: false,
      loadingState: false,
      screenSize: "",
      oldValue: false,
      formData: {
        start_date: null,
        end_date: null,
        page: null,
      },

      selected: [],

      headers: [
        { title: "Time", key: "request_timestamp" },
        {
          title: "Name",
          key: "log_name",
        },
        { title: "device_ip", key: "device_ip" },

        {
          title: "user_id",
          key: "user_id",
        },
        {
          title: "Company_id",
          key: "partner_id",
        },
        {
          title: "user email",
          key: "share_user",
        },
        {
          title: "device_details",
          key: "device_details",
        },
        {
          title: "log_details",
          key: "log_details",
        },
      ],
    };
  },

  computed: {
    emails() {
      return this.$store.getters["dashboard/dashboardGetters"]("activity");
    },

    loaderState() {
      return this.$store.getters["email/emailGetters"]("loaderState");
    },

    formattedStartDate: {
      get() {
        return this.selectedStartDate;
      },
      set(value) {
        this.selectedStartDate = this.formatDate(value);
      },
    },
    formattedEndDate: {
      get() {
        return this.selectedEndDate;
      },
      set(value) {
        this.selectedEndDate = this.formatDate(value);
      },
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    this.getCurrentDate()
  },
  methods: {
    submitFilters() {
      // Reset page to 1
      this.formData.page = 1;
      (this.formData.start_date = this.formattedStartDate),
        (this.formData.end_date = this.formattedEndDate),
        // Dispatch the cleaned data
        this.$store.dispatch("dashboard/getActivity", this.formData);
    },
    clearFilters() {
      // Get current date
     this.formattedStartDate = null
     this.formattedEndDate = null
    },
    formatDate(date) {
      if (!date) return "";
      const d = new Date(date);
      return d.toISOString().split("T")[0]; // Formats to YYYY-MM-DD
    },
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    addFilter(filterType) {
      // Toggle the show state for the selected filter
      if (filterType === "sent") {
        this.showSent = !this.showSent;
        this.formData.is_sent = this.showSent; // Set to the current toggle state
      } else if (filterType === "opened") {
        this.showOp = !this.showOp;
        this.formData.is_opened = this.showOp; // Set to the current toggle state
      } else if (filterType === "delivered") {
        this.showDel = !this.showDel;
        this.formData.is_delivered = this.showDel; // Set to the current toggle state
      } else if (filterType === "clicked") {
        this.showclicked = !this.showclicked;
        this.formData.is_clicked = this.showclicked; // Set to the current toggle state
      }
    },

    onPaginationChange() {
      // Update the offset state
      (this.formData.start_date = this.formattedStartDate),
        (this.formData.end_date = this.formattedEndDate),
        (this.formData.page += 1);
      this.$store.dispatch("dashboard/getActivity", this.formData);
    },

    onPaginationNextChange() {
      // Update the offset state
      (this.formData.start_date = this.formattedStartDate),
        (this.formData.end_date = this.formattedEndDate),
        (this.formData.page -= 1);
      this.$store.dispatch("dashboard/getActivity", this.formData);
    },
    getCurrentDate() {
      const now = new Date();

      // First day of the month (YYYY-MM-DD format)
      const firstDay = `${now.getFullYear()}-${String(
        now.getMonth() + 1
      ).padStart(2, "0")}-01`;

      // Last day of the month (YYYY-MM-DD format)
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      const formattedLastDay = `${lastDay.getFullYear()}-${String(
        lastDay.getMonth() + 1
      ).padStart(2, "0")}-${String(lastDay.getDate()).padStart(2, "0")}`;
      this.selectedStartDate= firstDay
      this.selectedEndDate = formattedLastDay
    }
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },

  },
};
</script>

<style>
@import url("../../emails/emailStyle.css");
</style>
