<template>
  <v-app class="container">
    <!-- Side Bar -->
    <v-navigation-drawer
      dark
      app
      class="rounded-drawer"
      color="#f0f8ff"
      v-model="drawer"
      :width="screenSize === 'Large' ? 230 : ''"
    >
      <div class="logo-bc">
        <v-img
          class="mx-auto mb-3"
          height="65"
          max-width="100"
          src="../../../logo.png"
        ></v-img>
      </div>
      <v-divider />

      <div>
        <ul nav class="nav-list">
          <span
            class="nav-link"
            v-for="(item, index) in navLinks"
            :key="item.title"
            :to="item.to"
            :href="item.href"
            :disabled="item.disabled"
          >
            <v-list-item-title
            :disabled="item.disabled"
              class="nav-title white--text mb-4 mt-6"
              @click="item.to !== '' ? navigate(item.to, item.title, '') : ''"
            >
              <v-row
                @click="!show ? openSubMenus(item, index) : close(item)"
                class="active-background"
              >
                <v-col>
                  <v-icon
                    class="ml-4"
                    :color="item.title === selectedMenu ? '#1e2229' : '#6b7382'"
                    :icon="item.icon"
                  ></v-icon>
                  <span
                    class="ml-3"
                    :class="
                      item.title === selectedMenu
                        ? 'active-nac-title'
                        : 'nac-title'
                    "
                    >{{ item.title }}</span
                  >
                </v-col>
                <v-col class="" align="end">
                  <v-icon
                    class="mr-6 mt-2"
                    small
                    v-if="item.children.length > 0"
                  >
                    {{
                      show && item.title === selectedMenu
                        ? "mdi-chevron-up"
                        : "mdi-chevron-down"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-list-item-title>
            <ul v-if="show && item.title === selectedMenu">
              <li
                v-for="(child, i) in item.children"
                :key="i"
                class="active-background"
              >
                <v-list-item-title
                  @click="navigate(child.to, item.title, child.title)"
                  :class="
                    child.title === selectedChild
                      ? 'sub-nav-title mb-4 mt-4 ml-n3'
                      : 'active-sub-nav-title mb-4 mt-4 ml-n3'
                  "
                >
                  <v-icon small class="ml-6">{{ child.icon }}</v-icon>
                  <span class="ml-6">{{ child.title }}</span></v-list-item-title
                >
              </li>
            </ul>
            <v-divider v-if="openSubmenu" />
          </span>
        </ul>
      </div>
    </v-navigation-drawer>

    <v-app-bar app color="" height="70" class="mt-n1" variant="flat">
      <template v-slot:prepend>
        <v-icon
          class="ml-4"
          size="large"
          color="#50568e"
          icon="mdi-format-align-left"
          @click="drawer = !drawer"
        ></v-icon>
      </template>

      <v-row class="logo-row">
        <v-spacer />
        <p class="mr-6 mt-2">{{ getFormattedDateTime() }}</p>

        <p class="logoS-text mt-2">
          {{ profile.email ? profile.email : "" }} [{{
            profile.company_name ? profile.company_name : ""
          }}]
        </p>

        <v-menu>
          <template v-slot:activator="{ props }">
            <v-avatar class="mr-2">
              <v-icon color="#111827" size="x-large" v-bind="props"
                >mdi-account-circle</v-icon
              >
            </v-avatar>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title class="profile-text">{{
                profile.is_admin ? "Admin" : "User"
              }}</v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item @click="logOut()"
              ><v-list-item-title>
                <v-icon color="#111827" size="small" v-bind="props"
                  >mdi-logout</v-icon
                >Log out</v-list-item-title
              ></v-list-item
            >
          </v-list>
        </v-menu>
      </v-row>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AuthService from "@/modules/auth/views/authService";

export default {
  name: "homePage",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("dashboard/getProDetails");
    });
  },

  data: () => ({
    rail: false,
    drawer: null,
    mini: false,
    selectedItem: 0,
    show: false,
    direction: "top",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    left: true,
    top: false,
    right: true,
    bottom: true,
    openSubmenu: false,
    selectedMenu: "",
    selectedChild: "",
    transition: "slide-x-reverse-transition",
    screenSize: "",
  }),

  computed: {
    auth() {
      return AuthService;
    },
    navLinks() {
      return [
        {
          to: "/dashboard/stats",
          icon: "mdi-chart-pie",
          title: "Dashboard",
          disabled: false,
          children: [],
        },
        {
          to: "",
          icon: "mdi-tooltip",
          title: "Subscribers",
          disabled: false,
          children: [
            {
              to: "/sub-list",
              title: "All Subscribers",
              icon: "",
              disabled: false,
              children: [],
            },
            {
              to: "/subscriber",
              title: "Add Subscriber ",
              icon: "",
              disabled: false,
              children: [],
            },
            {
              to: "/blacklisted-list",
              title: "Blacklist ",
              icon: "",
              disabled: false,
              children: [],
            },
          ],
        },
        {
          to: "",
          icon: "mdi-format-align-justify",
          title: "Lists",
          disabled: false,
          children: [
            {
              to: "/all-lists",
              title: "All Lists",
              icon: "",
              disabled: false,
              children: [],
            },
            {
              to: "/add-list",
              title: "Add List ",
              icon: "",
              disabled: false,
              children: [],
            },
          ],
        },
        {
          to: "",
          icon: "mdi-memory",
          title: "Templates",
          disabled: false,
          children: [
            {
              to: "/temp-list",
              title: "All Templates",
              icon: "",
              disabled: false,
              children: [],
            },
            {
              to: "/add-template",
              title: "Add Template ",
              icon: "",
              disabled: false,
              children: [],
            },
          ],
        },
        {
          to: "",
          icon: "mdi-memory",
          title: "Campaigns",
          disabled: false,
          children: [
            {
              to: "/all-campaigns",
              title: "All Campaigns",
              icon: "",
              disabled: false,
              children: [],
            },
            {
              to: "/add-campaign",
              title: "Add Campaign ",
              icon: "",
              disabled: false,
              children: [],
            },
            {
              to: "/emails-by-campaign",
              title: "Emails per Campaign ",
              icon: "",
              disabled: false,
              children: [],
            },
          ],
        },
        {
          to: "",
          icon: "mdi-email",
          title: "Emails",
          disabled: false,
          children: [
            {
              to: "/all-emails",
              title: " All Emails",
              icon: "",
              disabled: false,
              children: [],
            },
          ],
        },
        {
          to: "",
          icon: "mdi-account",
          title: "Users",
          disabled: true,
          children: [
            // {
            //   to: "/all-companies",
            //   title: "Companies",
            //   icon: "",
            //   disabled: false,
            //   children: [],
            // },
            // {
            //   to: "/dashboard/all-users",
            //   title: "System Users ",
            //   icon: "",
            //   disabled: true,
            //   children: [],
            // },
            {
              to: "/invite-user",
              title: "Invite user ",
              icon: "",
              disabled: false,
              children: [],
            },
          ],
        },
        // {
        //   to: "",
        //   icon: "mdi-account",
        //   title: "System Users",
        //   disabled: false,
        //   children: [
        //     {
        //       to: "/dashboard/all-users",
        //       title: "System Users ",
        //       icon: "",
        //       disabled: false,
        //       children: [],
        //     },
        //   ],
        // },
        // {
        //   to: "",
        //   icon: "mdi-account",
        //   title: "User Activity",
        //   disabled: false,
        //   children: [
        //     {
        //       to: "/dashboard/user-activity",
        //       title: "User Activity ",
        //       icon: "",
        //       disabled: false,
        //       children: [],
        //     },
        //   ],
        // },
      ];
    },
    profile() {
      return this.$store.getters["dashboard/dashboardGetters"]("profile");
    },
  },

  methods: {
    getFormattedDateTime() {
      const now = new Date();

      const options = {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Use 24-hour format
        timeZoneName: "short",
      };

      return now.toLocaleString("en-US", options);
    },
    logout() {
      AuthService.logout();
    },
    redirect() {
      this.$router.push({ name: "mainDashboard" });
    },
    openSubMenus(item, val) {
      (this.selectedChild = ""), (this.selectedMenu = "");
      console.log("item", item);
      this.selectedMenu = item.title;
      const navChildren = this.navLinks[val].children;
      this.children = [...navChildren];
      this.show = true;
    },
    close(val) {
      if (this.show && this.selectedMenu === val.title) {
        this.show = false;
      } else {
        this.selectedMenu = val.title;
        this.show = true;
      }
    },
    navigate(to, parentTitle, childTitle) {
      this.$router.push({ path: to });
      this.selectedMenu = parentTitle;
      this.selectedChild = childTitle;
      localStorage.setItem(
        "activeMenu",
        JSON.stringify({ parent: parentTitle, child: childTitle })
      );
    },
    logOut() {
      AuthService.logout();
    },

    setActiveMenuFromLocalStorage() {
      const activeMenu = JSON.parse(localStorage.getItem("activeMenu"));
      if (activeMenu) {
        this.selectedMenu = activeMenu.parent;
        this.selectedChild = activeMenu.child;
        if (activeMenu.child) {
          this.show = true;
        }
      }
    },

    findPath(val) {
      const subscriberObject = this.navLinks.find((obj) =>
        obj.children.some((child) => child.to === val)
      );
      console.log(subscriberObject);

      if (subscriberObject) {
        // Get the parent title
        const parentTitle = subscriberObject.title;

        // Get the child title whose link is "/subscriber"
        const childTitle = subscriberObject.children.find(
          (child) => child.to === val
        ).title;
        this.selectedMenu = parentTitle;
        this.selectedChild = childTitle;
        this.show = true;
      }
    },

    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 600px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },

  created() {
    this.setActiveMenuFromLocalStorage();
  },

  watch: {
    // selectedMenu: {
    //   handler: function (newValue, oldValue) {
    //     if (newValue !== oldValue) {
    //       var pathname = window.location.pathname;
    //       // Split the pathname into segments
    //       var segments = pathname.split('/');
    //       // Filter out empty segments and get the first valid segment
    //       var resultSegment = segments.filter(Boolean)[0];
    //       // Construct the desired path
    //       var resultPath = '';
    //       if (resultSegment) {
    //         resultPath = '/' + resultSegment;
    //       }
    //       this.findPath(resultPath)
    //     }
    //   },
    //   // immediate: true,
    // },
  },
};
</script>

<style>
@import url("../dashboardStyle.css");
</style>
