export default {
  emails: "api/sent-mails/",
  singleList: (id) => `api/sent-mails/?campaign_id=${id}`,
  status: (id) => `api/sent-mails/?campaign_id=${id}&is_delivered=false&all`,
  deliveredStatus: (id) =>
    `api/sent-mails/?campaign_id=${id}&is_delivered=true&all`,
  emailsPag: (id) => `api/paginated-sent-mails/?limit=10&offset=${id}`,
  filterEmails: (data) => {
    // Start with the required parameter
    let url = `api/filtered-sent-mails/?mail_campaign_id=${data.mail_campaign_id}`;
    
    // Only add parameters that have true values or non-null values
    if (data.is_delivered === true) url += `&is_delivered=${data.is_delivered}`;
    if (data.is_opened === true) url += `&is_opened=${data.is_opened}`;
    if (data.is_clicked === true) url += `&is_clicked=${data.is_clicked}`;
    if (data.is_sent === true) url += `&is_sent=${data.is_sent}`;
    if (data.page) url += `&page=${data.page}`;
    if (data.to_email) url += `&to_email=${data.to_email}`;
    
    return url;
  }
 
};
