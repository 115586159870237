export default {
    subscribers: "api/subscriber-groups/",
    saveSub:"api/subscribers/",
    delSub:(id) => `api/subscribers/${id}/`,
    blackSub:"api/subscribers/?is_blacklisted=true",
    subTrue:"api/subscribers/?is_subscribed=true",
    subFalse:"api/subscribers/?is_subscribed=false",
    subID:(id) => `api/subscriber-groups/${id}/`,
    deleteMultiple:"api/subscribers/bulk-delete/",
    pagSub:"api/subscribers/list/",
    newPageSub:"api/subscribers/paginated/?limit=10&offset=0&sort_by=email",
    test:(id) => `api/subscribers/paginated/?limit=10&offset=${id}`,
    filterSubs: (data) => {
      // Start with the required parameter
      let url = `api/subscribers/paginated/?limit=10`;

      
      // Only add parameters that have true values or non-null values
      if (data.email !==null ) url += `&email=${data.email}&sort_by=email`;
      if (data.is_subscribed === true) url += `&is_subscribed=${data.is_subscribed}&sort_by=email`;
      if (data.is_blacklisted === true) url += `&is_blacklisted=${data.is_blacklisted}&sort_by=email`;
      if (data.unsubscribed === true) url += `&is_subscribed=false&sort_by=email`;
      if (data.group_id) url += `&group_id=${data.group_id}&sort_by=email`;
      if (data.offset !==null) url += `&offset=${data.offset}&sort_by=email`;

      // console.log(url); 
  
      
      return url;
    }
  }