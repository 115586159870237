<template>
  <div class="sub-container">
    <span class="mt-n3 ml-6 head-sub-text"> Invite User</span>
    <v-card flat="flat" class="mx-6 mt-6">
      <v-card-text>
        <v-form ref="subForm" v-model="isValid">
          <v-row class="mt-n6"
            ><v-col class="mx-3" :cols="12" :md="2" :sm="3">Email</v-col
            ><v-col :cols="12" :md="6" :sm="3" align-self="start"
              ><v-text-field
                class="mt-2 mx-auto"
                ref="lname"
                :rules="rules.email"
                v-model="formData.email"
                density="compact"
                style="color: #3c3731"
                variant="outlined" /></v-col
          ></v-row>
          <v-row class="mt-n6"
            ><v-col class="mx-3" :cols="12" :md="2" :sm="3">Company</v-col
            ><v-col :cols="12" :md="6" :sm="3" align-self="start">
              <v-autocomplete
                v-model="formData.company_id"
                :items="companies.length > 0 ? companies : []"
                color="blue-grey-lighten-2"
                item-title="name"
                item-value="id"
                label="Select"
                chips
                closable-chips
                density="compact"
                style="color: #3c3731"
                variant="outlined"
              ></v-autocomplete></v-col
          ></v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="ml-6">
        <v-btn
          @click="routeParams ? edit() : save()"
          color="#50568e"
          variant="flat"
        >
          <span class="btn-text" v-if="!loadingState">
            {{ routeParams ? "Edit" : "Send Invite" }}</span
          >
          <v-progress-circular
            :width="3"
            color="#50568e"
            indeterminate
            v-if="loadingState"
          ></v-progress-circular>
        </v-btn>

        <v-btn
          color="#343a40"
          width="80"
          variant="flat"
          :to="{ name: 'Dashboard' }"
        >
          <span class="btn-text">Cancel</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import eventBus from "@/utils/eventBus";
export default {
  name: "addCompany",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("roles/getCompanies");
    });
  },

  data() {
    return {
      isValid: false,
      allSubscribers: [],
      loadingState: false,
      screenSize: "",
      oldValue: false,
      test: [],
      newFiltered: [],
      formData: {
        name: "",
        company_id: null,
      },

      rules: {
        required: [(value) => !!value || "Required."],
        email: [
          (value) => !!value || "Required.",
          (value) => /.+@.+\..+/.test(value) || "Invalid email address.",
        ],
        password: [(value) => !!value || "Required."],
      },
    };
  },

  computed: {
    loaderState() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "loaderState"
      );
    },

    routeId() {
      return parseInt(this.$route.params.id);
    },

    companies() {
      return this.$store.getters["roles/rolesGetters"]("companies");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    eventBus.on("redirectToList", () => {
      this.$router.push({ name: "Companies" });
    });
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    save() {
      if (!this.isValid) {
        this.$refs.subForm.validate();
      } else {
        if (this.formData.company_id === null) {
          eventBus.emit("showToast", {
            type: "error",
            message: "All Fields are required",
          });
        } else {
          this.$store.dispatch("roles/sendInvite", this.formData);
        }
      }
    },

    edit() {
      if (!this.isValid) {
        this.$refs.subForm.validate();
      } else {
        this.formData.listId = parseInt(this.$route.params.id);
        this.formData.subscribers = [...this.newFiltered];

        this.$store.dispatch("roles/editCompany", this.formData);
      }
    },

    getObject(val) {
      console.log(val);
      return val;
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style>
@import url("../rolesStyles.css");
</style>
