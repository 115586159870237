<template>
  <!-- <div class="stats-container"> -->
  <v-container fluid class="stats-page">
    <h3 class="mt-n3 ml-6">Dashboard</h3>

    <v-card-actions class="test-field mb-3 ml-3">
      <div class="test-field px-2">
        <p class="start-text">Start Date</p>
        <input
          class="date-field start-text"
          type="date"
          v-model="formattedStartDate"
        />
      </div>
      <div class="test-field px-2">
        <p class="start-text">End Date</p>
        <input
          class="date-field start-text"
          type="date"
          v-model="formattedEndDate"
        />
      </div>

      <v-spacer />

      <v-btn
        color="red"
        variant="flat"
        class="text-none"
        size="small"
        prepend-icon="mdi-magnify"
        @click="submitFilters"
      >
        <span class="btn-text">Search</span>
      </v-btn>

      <v-btn
        color="teal"
        variant="flat"
        class="text-none"
        prepend-icon="mdi-delete-empty"
        size="small"
        @click="clearFilters"
      >
        <span class="btn-text">Clear Filters</span>
      </v-btn>
      <v-spacer />
    </v-card-actions>
    <v-row class="mt-n3"
      ><v-col cols="12" md="4" sm="12">
        <v-row class="mt-6">
          <v-sheet
            variant="flat"
            rounded="lg"
            elevation="0"
            height="100"
            width="500"
          >
            <v-row>
              <v-avatar class="ml-8 mt-8" size="54" color="#50568e" rounded="">
                <v-icon color="white" icon="mdi-vanish"></v-icon>
              </v-avatar>
              <span class="ml-3 mt-8">
                <div class="stats-text">
                  {{ stats ? formatNumber(stats.total_campaigns) : "N/A" }}
                </div>

                <span class="campaign-text">Total Campaigns</span>
              </span>
            </v-row>
          </v-sheet></v-row
        >
        <v-row class="mt-6">
          <v-sheet
            variant="flat"
            rounded="lg"
            elevation="0"
            height="100"
            width="500"
          >
            <v-row>
              <v-avatar class="ml-8 mt-8" size="54" color="#50568e" rounded="">
                <v-icon color="white" icon="mdi-tooltip"></v-icon>
              </v-avatar>
              <span class="ml-3 mt-8">
                <div class="stats-text">
                  {{ stats ? formatNumber(stats.total_subscribers) : "N/A" }}
                </div>

                <span class="campaign-text">Total Subscribers</span>
              </span>
            </v-row>
          </v-sheet></v-row
        >
        <v-row class="mt-6">
          <v-sheet
            variant="flat"
            rounded="lg"
            elevation="0"
            height="100"
            width="500"
          >
            <v-row>
              <v-avatar class="ml-8 mt-8" size="54" color="#50568e" rounded="">
                <v-icon color="white" icon="mdi-view-sequential"></v-icon>
              </v-avatar>
              <span class="ml-3 mt-8">
                <div class="stats-text">
                  {{ stats ? formatNumber(stats.total_unsubscribers) : "N/A" }}
                </div>

                <span class="campaign-text">Total unsubscribers</span>
              </span>
            </v-row>
          </v-sheet></v-row
        > </v-col
      ><v-col cols="12" md="8" sm="12">
        <v-card class="graph-card" variant="flat">
          <CanvasJSChart :options="options" />
        </v-card> </v-col
    ></v-row>

    <v-row class="mt-6">
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex align-center"
          elevation="0"
          height="100"
        >
          <span class="ml-6">
            <div class="stats-text">
              {{ stats ? formatNumber(stats.total_number_sent) : "N/A" }}
            </div>
            <span class="campaign-text">Total sent</span>
          </span>
        </v-sheet>
      </v-col>
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex align-center"
          elevation="0"
          height="100"
        >
          <span class="ml-6">
            <div class="stats-text">
              {{ stats ? formatNumber(stats.total_number_delivered) : "N/A" }}
            </div>
            <span class="campaign-text">Total deliveries</span>
          </span>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex align-center"
          elevation="0"
          height="100"
        >
          <span class="ml-6">
            <div class="stats-text">
              {{ stats ? (stats.total_bounces) : "N/A" }}
            </div>
            <span class="campaign-text">Total bounces</span>
          </span>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex align-center"
          elevation="0"
          height="100"
        >
          <span class="ml-6">
            <div class="stats-text">
              {{ stats ? (stats.total_number_opened) : "N/A" }}
            </div>
            <span class="campaign-text">Total opens</span>
          </span>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex align-center"
          elevation="0"
          height="100"
        >
          <span class="ml-6">
            <div class="stats-text">
              {{ stats ? (stats.total_number_clicked) : "N/A" }}
            </div>
            <span class="campaign-text">Total clicks</span>
          </span>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex align-center"
          elevation="0"
          height="100"
        >
          <span class="ml-6">
            <div class="stats-text">
              {{ stats ? (stats.total_unsubscribers) : "N/A" }}
            </div>
            <span class="campaign-text">Total unsubscribers</span>
          </span>
        </v-sheet></v-col
      >
    </v-row>
    <v-row class="mt-6"
      ><v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex flex-column justify-center align-center"
          elevation="0"
          height="150"
        >
          <div class="text-center">
            <v-progress-circular
              :model-value="value"
              :rotate="360"
              :size="80"
              :width="3"
              color="#50568e"
            >
              <template v-slot:default
                >{{
                  stats ? (stats.average_bounce_rate) : 0
                }}%</template
              >
            </v-progress-circular>
          </div>
          <div class="text-center mt-2 campaign-text">Bounce rate</div>
        </v-sheet>
      </v-col>
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex flex-column justify-center align-center"
          elevation="0"
          height="150"
        >
          <div class="text-center">
            <v-progress-circular
              :model-value="value"
              :rotate="360"
              :size="80"
              :width="3"
              color="#50568e"
            >
              <template v-slot:default
                >{{
                  stats ? (stats.average_click_rate) : 0
                }}%</template
              >
            </v-progress-circular>
          </div>
          <div class="text-center mt-2 campaign-text">Click rate</div>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex flex-column justify-center align-center"
          elevation="0"
          height="150"
        >
          <div class="text-center">
            <v-progress-circular
              :model-value="value"
              :rotate="360"
              :size="80"
              :width="3"
              color="#50568e"
            >
              <template v-slot:default
                >{{
                  stats ? (stats.average_open_rate) : 0
                }}%</template
              >
            </v-progress-circular>
          </div>
          <div class="text-center mt-2 campaign-text">Open rate</div>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex flex-column justify-center align-center"
          elevation="0"
          height="150"
        >
          <div class="text-center">
            <v-progress-circular
              :model-value="value"
              :rotate="360"
              :size="80"
              :width="3"
              color="#50568e"
            >
              <template v-slot:default
                >{{
                  stats ? (stats.sending_rate) : "N/A"
                }}%</template
              >
            </v-progress-circular>
          </div>
          <div class="text-center mt-2 campaign-text">Sending rate</div>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex flex-column justify-center align-center"
          elevation="0"
          height="150"
        >
          <div class="text-center">
            <v-progress-circular
              :model-value="value"
              :rotate="360"
              :size="80"
              :width="3"
              color="#50568e"
            >
              <template v-slot:default
                >{{
                  stats ? (stats.delivery_rate) : "N/A"
                }}%</template
              >
            </v-progress-circular>
          </div>
          <div class="text-center mt-2 campaign-text">Delivery rate</div>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex flex-column justify-center align-center"
          elevation="0"
          height="150"
        >
          <div class="text-center">
            <v-progress-circular
              :model-value="value"
              :rotate="360"
              :size="80"
              :width="3"
              color="#50568e"
            >
              <template v-slot:default
                >{{
                  stats ? (stats.unsubscribe_rate) : "N/A"
                }}%</template
              >
            </v-progress-circular>
          </div>
          <div class="text-center mt-2 campaign-text">unsubscribe rate</div>
        </v-sheet></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "StatsPage",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      // Get current date
      const now = new Date();

      // First day of the month (YYYY-MM-DD format)
      const firstDay = `${now.getFullYear()}-${String(
        now.getMonth() + 1
      ).padStart(2, "0")}-01`;

      // Last day of the month (YYYY-MM-DD format)
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      const formattedLastDay = `${lastDay.getFullYear()}-${String(
        lastDay.getMonth() + 1
      ).padStart(2, "0")}-${String(lastDay.getDate()).padStart(2, "0")}`;

      const data = {
        start_date: firstDay,
        end_date: formattedLastDay,
     
      };

      v.$store.dispatch("dashboard/getStats", data);
    });
  },

  data() {
    return {
      screenSize: "",
      selectedEndDate: null,
      selectedStartDate: null,
      
      value: 0,
      chart: null,
      formData: {
        start_date: null,
        end_date: null,
      },
    };
  },

  computed: {
    stats() {
      return this.$store.getters["dashboard/dashboardGetters"]("stats");
    },
    formattedStartDate: {
      get() {
        return this.selectedStartDate;
      },
      set(value) {
        this.selectedStartDate = this.formatDate(value);
      },
    },
    formattedEndDate: {
      get() {
        return this.selectedEndDate;
      },
      set(value) {
        this.selectedEndDate = this.formatDate(value);
      },
    },
    options() {
      return {
        animationEnabled: true,
        exportEnabled: true,
        theme: "light2", // Choose from "light1", "light2", "dark1", "dark2"
        title: {
          text: "Email Campaign Performance",
          fontFamily: "Arial",
          fontSize: 24,
          fontWeight: "bold",
        },

        subtitles: [
          {
            text: "Campaign Metrics Overview",
            fontSize: 16,
          },
        ],
        axisX: {
          gridThickness: 0,
          lineThickness: 1,
          labelFontFamily: "Arial",
          labelFontSize: 12,
        },
        axisY: {
          gridThickness: 0,
          lineThickness: 1,
          labelFontFamily: "Arial",
          labelFontSize: 12,
          title: "Values",
          titleFontFamily: "Arial",
          titleFontSize: 16,
        },
        data: [
          {
            type: "bar",
            indexLabel: "{y}",
            indexLabelFontFamily: "Arial",
            indexLabelFontSize: 12,
            indexLabelPlacement: "outside",
            indexLabelFontColor: "#555",
            dataPoints: [
              {
                label: "Total Number Sent",
                y: this.stats.total_number_sent,
                color: "#3498db", // Blue
              },
              {
                label: "Total Number Delivered",
                y: this.stats.total_number_delivered,
                color: "#2ecc71", // Green
              },
              {
                label: "Total Number Opened",
                y: this.stats.total_number_opened,
                color: "#9b59b6", // Purple
              },
              {
                label: "Total Number Clicked",
                y: this.stats.total_number_clicked,
                color: "#e74c3c", // Red
              },
              {
                label: "Average Bounce Rate",
                y: this.stats.average_bounce_rate,
                color: "#f39c12", // Orange
              },
              {
                label: "Average Open Rate",
                y: this.stats.average_open_rate,
                color: "#1abc9c", // Turquoise
              },
              {
                label: "Average Click Rate",
                y: this.stats.average_click_rate,
                color: "#34495e", // Dark Blue
              },
            ],
          },
        ],
        toolTip: {
          shared: false,
          contentFormatter: function (e) {
            return (
              e.entries[0].dataPoint.label + ": " + e.entries[0].dataPoint.y
            );
          },
        },
      };
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    this.getCurrentDate();
  },
  methods: {
    getCurrentDate() {
      const now = new Date();

      // First day of the month (YYYY-MM-DD format)
      const firstDay = `${now.getFullYear()}-${String(
        now.getMonth() + 1
      ).padStart(2, "0")}-01`;

      // Last day of the month (YYYY-MM-DD format)
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      const formattedLastDay = `${lastDay.getFullYear()}-${String(
        lastDay.getMonth() + 1
      ).padStart(2, "0")}-${String(lastDay.getDate()).padStart(2, "0")}`;
      this.selectedStartDate= firstDay
      this.selectedEndDate = formattedLastDay
    },
    formatNumber(value) {
      return value ? new Intl.NumberFormat().format(value) : "N/A";
    },
    submitFilters() {
      // Reset page to 1

      (this.formData.start_date = this.formattedStartDate),
        (this.formData.end_date = this.formattedEndDate),
        // Dispatch the cleaned data
        this.$store.dispatch("dashboard/getStats", this.formData);
    },
    clearFilters() {
      // Get current date
      this.formattedStartDate = null;
      this.formattedEndDate = null;
    },
    formatDate(date) {
      if (!date) return "";
      const d = new Date(date);
      return d.toISOString().split("T")[0]; // Formats to YYYY-MM-DD
    },
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
  },

  watch: {},
};
</script>

<style>
.start-text {
  font-size: 13px;
}
</style>
