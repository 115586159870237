import call from "@/service/http";
import constants from "./dashboardConstants";
import eventBus from "@/utils/eventBus";
// import AuthService from "../auth/views/authService";

const dashboardModule = {
  namespaced: true,
  state: {
    stats: [],
    users: [],
    activity: [],
    profile: {},
    user: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    dashboardGetters: (state) => (val) => state[val],
  },
  actions: {
    getStats: ({ commit }, data) => {
      call("get", constants.stats(data))
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", { state: "stats", value: res.data.data });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProDetails: ({ commit }) => {
      call("get", constants.prof)
        .then((res) => {
          commit("MUTATE", { state: "profile", value: res.data.data });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getActivity: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.filterActivity(data))
        .then((res) => {
          // console.log(res.data)
          if (res.status === 200) {
            commit("MUTATE", { state: "activity", value: res.data.results });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },

    getUsers: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.filterUsers(data))
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            commit("MUTATE", { state: "users", value: res.data.results });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },
    getSingleUser: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.singleUser(data))
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            commit("MUTATE", { state: "user", value: res.data });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    editSingleUser: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("put", constants.singleUser(data.listId), data)
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });
            window.location.reload();
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
            window.location.reload();
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },

    deleteSingleUser: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("delete", constants.singleUser(data.listId), data)
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });
            window.location.reload();
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
            window.location.reload();
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
  },
};

export default dashboardModule;
