<template>
  <div class="sub-container">
    <span class="mt-n3 ml-6 head-sub-text">
      {{ routeParams ? "Edit" : "Add" }} Template</span
    >

    <v-card class="mx-6 mt-6">
      <v-card-text>
        <v-form ref="subForm" v-model="isValid">
          <v-row class="mt-n6"
            ><v-col class="mx-3 mt-3" :cols="12" :md="2" :sm="3"
              >Template name</v-col
            ><v-col :cols="12" :md="6" :sm="3" align-self="start"
              ><v-text-field
                class="mt-2 mx-auto"
                ref="fname"
                :rules="rules.required"
                v-model="formData.name"
                density="compact"
                style="color: #3c3731"
                variant="outlined" /></v-col
          ></v-row>

          <v-card-actions class="ml-6">
            <v-btn color="red" variant="text" class="text-none">
              <span class="var-btn-text">{{ subscriberName }} </span>
            </v-btn>
            <!-- <v-btn color="red" variant="flat" @click="exportHtml">
              <span class="btn-text">Export HTML</span>
            </v-btn> -->
          </v-card-actions>

          <v-alert
            max-height=""
            max-width="600"
            text
            class="mt-2"
            variant="outlined"
            color="teal"
            icon="mdi-alert"
          >
            <span class="dash-font-style"
              >Click File Chip after upload below the to preview it and download
              it !
            </span>
          </v-alert>
          <v-card-text>
            <v-btn
              name="files"
              color="primary"
              @click="triggerFileUpload"
              prepend-icon="mdi-upload"
              density="compact"
              class="mt-3 ml-6"
            >
              Upload Files
            </v-btn>
          
              <input
                type="file"
                ref="fileInput"
                @change="onFileChange"
                accept=".pdf, .doc, .docx"
                style="display: none"
              />

              <!-- Display selected file names (optional) -->
              <div
                v-if="newfiles.length > 0"
                class="mt-3 d-flex flex-wrap gap-2"
              >
                <v-chip
                  v-for="(file, index) in newfiles"
                  :key="index"
                  size="small"
                  closable
                  @click="openFilePreview(file)"
                  @click:close="removeFile(index)"
                >
                  {{ file.name }}
                </v-chip>
              </div>
         
          </v-card-text>

          <v-divider class="mt-3" />

          <v-card-text v-if="alluploadedFiles.length > 0">
            <span class="dash-font-style">Previously Uploaded files</span>
            <div
              v-if="alluploadedFiles.length > 0"
              class="d-flex flex-wrap gap-2"
            >
              <v-chip
                v-for="(file, index) in alluploadedFiles"
                :key="index"
                color="green"
                variant="flat"
                size="small"
                closable
                @click="openFilePreview(file)"
                @click:close="removeFile(index)"
              >
                {{ file.name }}
              </v-chip>
            </div>
          </v-card-text>

          <v-btn-toggle
            class="mb-6 mt-6"
            v-model="text"
            variant="outlined"
            color="deep-purple-accent-6"
            rounded="0"
            group
          >
            <v-btn
              color="blue"
              value="normal"
              :size="screenSize === 'Large' ? 'small' : 'x-small'"
            >
              Normal Editor
            </v-btn>

            <v-btn
              color="blue"
              :size="screenSize === 'Large' ? 'small' : 'x-small'"
              value="unlayer"
            >
              Unlayer Editor
            </v-btn>
          </v-btn-toggle>

          <QuillEditor
            v-if="text === 'normal'"
            theme="snow"
            class="quill-editor mt-6"
            v-model:content="normalEditor"
            contentType="html"
            :options="editorOptions"
          />

          <EmailEditor
            v-if="text === 'unlayer'"
            ref="emailEditor"
            class="vunlayer-editor"
            :projectId="projectId"
            v-on:load="editorLoaded"
            v-on:ready="editorReady"
          />

          <v-dialog v-model="previewDialog" max-width="">
            <v-card v-if="selectedFile">
              <v-card-title>
                File Preview: {{ selectedFile.name }}
                <v-spacer></v-spacer>
                <!-- <v-btn icon @click="previewDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn> -->
              </v-card-title>

              <v-card-text class="preview-content">
                <template v-if="isPreviewable(selectedFile)">
                  <!-- <iframe
                    v-if="getFilePreviewUrl(selectedFile)"
                    :src="getFilePreviewUrl(selectedFile)"
                    width="100%"
                    height="1000px"
                    frameborder="0"
                  ></iframe> -->

                  <embed
                    v-if="getFilePreviewUrl(selectedFile)"
                    :src="getFilePreviewUrl(selectedFile)"
                    width="100%"
                    height="700px"
                  />

                  <v-alert v-else type="info" class="mt-4">
                    Preview not available for this file type.
                  </v-alert>
                </template>
                <v-alert v-else type="info" class="mt-4">
                  Unable to preview this file type.
                </v-alert>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn color="primary" @click="downloadFile(selectedFile)">
                  Download
                </v-btn> -->

                <v-btn
                  class="text-white flex-grow-1 text-none"
                  color="error"
                  prepend-icon="mdi-close"
                  rounded="0"
                  variant="flat"
                  @click="previewDialog = false"
                >
                  close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- <v-card-text class="mt-3">
            <v-file-upload
              clearable
              @change="onFileChange"
              ref="fileUpload"
              accept=".pdf, .doc, .docx"
              label="Upload"
              icon="mdi-cloud-upload"
              v-model="newfiles"
              name="files"
              max-width="300"
              max-height="200"
              :messages="['PDF or Word documents only']"
              browse-text="BROWSE"
              divider-text="or"
              density="compact"
              variant="compact"
              class="small-upload-button mt-2 mb-n4"
              style="display: none"
              multiple
            ></v-file-upload>
          </v-card-text> -->
        </v-form>
      </v-card-text>

      <v-card-actions class="ml-6 mt-12">
        <v-btn
          @click="routeParams ? editHtml() : exportHtml()"
          color="#50568e"
          width="80"
          variant="flat"
        >
          <span class="btn-text" v-if="!loadingState">Save</span>
          <v-progress-circular
            :width="3"
            color="#50568e"
            indeterminate
            v-if="loadingState"
          ></v-progress-circular>
        </v-btn>

        <v-btn
          color="#343a40"
          width="80"
          variant="flat"
          :to="{ name: 'templateList' }"
        >
          <span class="btn-text">Cancel</span>
        </v-btn>

        <!-- <v-btn color="red" variant="flat" @click="saveNormal">
          <span class="btn-text">Normal Editor Design</span>
        </v-btn> -->

        <!-- <v-btn color="green" variant="flat" @click="saveDesign">
          <span class="btn-text">Save Design</span>
        </v-btn>
        <v-btn color="red" variant="flat" @click="loadDesign">
          <span class="btn-text">Load Design</span>
        </v-btn> -->

        <!-- <v-btn color="red" variant="flat" @click="exportHtml">
          <span class="btn-text">Export HTML</span>
        </v-btn> -->
      </v-card-actions>
    </v-card>

    <v-dialog v-model="loaderState" max-width="500">
      <v-card>
        <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
        <v-card-actions
          ><v-spacer />
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>

          <v-spacer />
        </v-card-actions>

        <v-progress-linear
          class="mx-2 mt-n3"
          color="teal"
          indeterminate
        ></v-progress-linear>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { EmailEditor } from "vue-email-editor";
import eventBus from "@/utils/eventBus";
import { QuillEditor } from "@vueup/vue-quill";
import { AuthService } from "@/modules/auth";

// import QuillEditor2 from "./QuillEditor2.vue";
export default {
  name: "addTemplate",
  components: {
    EmailEditor,
    QuillEditor,
    // QuillEditor2
    // Editor
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (v.$route.params.id !== "") {
        v.$store.dispatch(
          "templates/getSingleTemplate",
          parseInt(v.$route.params.id)
        );
      }
    });
  },

  data() {
    return {
      selectedFile: null,
      previewDialog: false,
      fileNames: [],
      newfiles: [],
      alluploadedFiles: [],
      show: true,
      file: null,
      fileName: "",
      text: "normal",
      loading: false,
      textareaValue: "",
      textareaContent: "",
      cursorPosition: 0,
      subscriberName: "{{name}}",
      minHeight: "1000px",
      locale: "en",
      projectId: 106879,
      tools: {
        image: {
          enabled: false,
        },
      },
      editorOptions: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["link", "image", "video"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ align: [] }],
          ["clean"],
          ["html"], // Add the custom HTML button
          ["image", "code-block"],
          ["attachment"],
        ],
      },

      isValid: false,
      loadingState: false,
      screenSize: "",
      oldValue: false,
      hasAlreadyLoaded: false,
      test: [],
      normalEditor: "",
      attachments: [],
      formData: {
        name: "",
        content: "",
        json_data: {},
        files: [],
      },

      rules: {
        required: [(value) => !!value || "Required."],
        email: [
          (value) => !!value || "Required.",
          (value) => /.+@.+\..+/.test(value) || "Invalid email address.",
        ],
        password: [(value) => !!value || "Required."],
      },
      jsonData: null,
    };
  },

  computed: {
    loaderState() {
      return this.$store.getters["templates/templatesGetters"]("loaderState");
    },

    singleTemplate() {
      return this.$store.getters["templates/templatesGetters"]("template");
    },

    routeParams() {
      const id = this.$route.params.id;
      if (id !== "" && id !== "NaN" && id !== undefined) {
        return true;
      } else {
        return false;
      }
    },

    routeParamsId() {
      return this.$route.params.id !== "";
    },
    testId() {
      return this.$route.params.id;
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);

    eventBus.on("creationSuccess", () => {
      this.$router.push({ name: "templateList" });
    });
  },

  methods: {
    removeFile(index) {
      this.newfiles.splice(index, 1);
    },
    openFilePreview(file) {
      this.selectedFile = file;
      this.previewDialog = true;
    },
    isPreviewable(file) {
      // Define previewable file types
      const previewableTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "image/gif",
        "text/plain",
      ];

      // If file.type is undefined, try to infer it from the file URL
      if (!file.type && file.url) {
        const extension = file.url.split(".").pop().toLowerCase();

        const extensionToMimeType = {
          pdf: "application/pdf",
          jpg: "image/jpeg",
          jpeg: "image/jpeg",
          png: "image/png",
          gif: "image/gif",
          txt: "text/plain",
        };

        file.type = extensionToMimeType[extension] || "";
      }

      return previewableTypes.includes(file.type);
    },

    getFilePreviewUrl(file) {
      // Ensure file is a valid Blob or File before creating object URL
      if (file instanceof Blob || file instanceof File) {
        if (
          file.type === "application/pdf" ||
          ["image/jpeg", "image/png", "image/gif"].includes(file.type)
        ) {
          return URL.createObjectURL(file);
        }
      }

      // Return URL directly if it's already a URL
      if (file.url) {
        return encodeURI(file.url); // Ensures spaces and special characters are properly formatted
      }

      return null;
    },

    downloadFile(file) {
      // Create a download link and trigger download
      const url = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },

    onFileChange(event) {
      const uploadedFiles = event.target.files;

      if (uploadedFiles.length > 0) {
        this.formData.files = Array.from(uploadedFiles);
        this.attachments = Array.from(uploadedFiles);
        this.newfiles = Array.from(uploadedFiles);
      }
    },

    saveNormal() {
      this.formData.content = this.normalEditor;
      console.log(this.formData);
    },
    editorReady() {
      this.loading = true;
    },
    editorLoaded() {
      this.hasAlreadyLoaded = true;
    },
    redirectPage() {
      this.$router.push({ name: "subscriberList" });
    },

    saveDesign() {
      this.$refs.emailEditor.editor.saveDesign((design) => {
        this.formData.json_data = { ...design };
      });
    },

    loadDesign(val) {
      try {
        // Check if jsonData is serializable
        const stringifiedData = JSON.stringify(val);
        const parsedData = JSON.parse(stringifiedData);

        this.$refs.emailEditor.editor.loadDesign(parsedData);
      } catch (error) {
        console.error("Serialization error:", error);
      }
    },

    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    exportHtml() {
      if (this.text === "normal") {
        this.save();
      } else {
        this.saveDesign();
        this.$refs.emailEditor.editor.exportHtml((data) => {
          this.formData.content = data.html;
          this.save();
        });
      }
    },
    // save() {
    //   if (!this.isValid) {
    //     this.$refs.subForm.validate();
    //   } else {
    //     if (this.text === "normal") {
    //       this.formData.content = this.normalEditor;
    //     }
    //     this.$store.dispatch("templates/saveTemplate", this.formData);
    //   }
    // },

    // edit() {
    //   if (!this.isValid) {
    //     this.$refs.subForm.validate();
    //   } else {
    //     if (this.text === "normal") {
    //       this.formData.content = this.normalEditor;
    //     }
    //     const data = {
    //       alldata: this.formData,
    //       id: parseInt(this.$route.params.id),
    //     };
    //     this.$store.dispatch("templates/editTemplate", data);
    //   }
    // },

    save() {
      if (!this.isValid) {
        this.$refs.subForm.validate();
        return; // Stop execution if form is invalid
      }

      if (this.text === "normal") {
        this.formData.content = this.normalEditor;
      }

      // Create FormData object
      const formData = new FormData();
      formData.append("name", this.formData.name);
      formData.append("content", this.formData.content);
      formData.append("json_data", JSON.stringify(this.formData.json_data));

      if (this.formData.files && this.formData.files.length > 0) {
        this.formData.files.forEach((file) => {
          formData.append("files", file); // Ensure 'file' is a File object
        });
      }

      console.log("FormData to be sent:", formData);

      // API call using fetch with Bearer Token
      fetch("https://tmailapi.tililtechnologies.com/api/mail-templates/", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${AuthService.token}`, // Add Bearer token
        },
        body: formData, // FormData automatically sets Content-Type to multipart/form-data
      })
        .then((response) => response.json()) // Convert response to JSON
        .then((data) => {
          if (data.status_code === 200) {
            console.log("Success:", data);
            alert("Template saved successfully!");
          } else {
            console.error("Error:", data);
            alert("Failed to save template.");
          }
        })
        .catch((error) => {
          console.error("Fetch Error:", error);
          alert("An error occurred while saving the template.");
        });
    },

    edit() {
      if (!this.isValid) {
        this.$refs.subForm.validate();
      } else {
        if (this.text === "normal") {
          this.formData.content = this.normalEditor;
        }

        // Create a FormData object for the file upload

        const formData = new FormData();
        formData.append("name", this.formData.name);
        formData.append("content", this.formData.content);
        formData.append("json_data", JSON.stringify(this.formData.json_data));

        if (this.formData.files && this.formData.files.length > 0) {
          this.formData.files.forEach((file) => {
            formData.append("files", file); // Ensure 'file' is a File object
          });
        }

        const data = {
          alldata: formData,
          id: parseInt(this.$route.params.id),
        };

        this.$store.dispatch("templates/editTemplate", data);
      }
    },
    editHtml() {
      if (this.text === "normal") {
        this.edit();
      } else {
        this.saveDesign();
        this.$refs.emailEditor.editor.exportHtml((data) => {
          this.formData.content = data.html;

          this.edit();
        });
      }
    },
    insertTextAtCursor(text) {
      // Accessing the EmailEditor component instance
      const emailEditor = this.$refs.emailEditor;
      console.log(emailEditor);

      // Checking if the EmailEditor has a method or a way to access its underlying textarea
      if (emailEditor && emailEditor.textareaElement) {
        const textarea = emailEditor.textareaElement;
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;

        // Insert the text at the cursor position
        textarea.value =
          textarea.value.substring(0, startPos) +
          text +
          textarea.value.substring(endPos);

        // Move the cursor to the end of the inserted text
        textarea.selectionStart = startPos + text.length;
        textarea.selectionEnd = startPos + text.length;
      } else {
        console.error("Failed to access the EmailEditor textarea element.");
      }
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
    singleTemplate: {
      handler: function () {
        if (this.singleTemplate !== "undefined") {
          this.formData = { ...this.singleTemplate };
        }
        if (!this.singleTemplate.content.startsWith("<!DOCTYPE HTML PUBLIC")) {
          this.normalEditor = this.singleTemplate.content;
          this.text = "normal";
        } else {
          this.text = "unlayer";
        }
        const updatedAttachments = this.singleTemplate.attachments.map(
          (att) => ({
            ...att,
            url: `http://173.249.26.131/${att.url}`,
          })
        );

        this.alluploadedFiles = [...updatedAttachments];
      },
    },
    loading: {
      handler: function () {
        if (this.loading) {
          this.loadDesign(this.singleTemplate.json_data);
          this.formData.name = this.singleTemplate.name;
        }
      },
    },
  },
};
</script>

<style>
@import url("../templatesStyle.css");
</style>
