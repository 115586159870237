export default {
  // campaigns: (data) =>
  //   `api/mail-campaigns/?start_date=${data.start_date}&end_date=${data.end_date}`,
  singleCampaign: (id) => `api/mail-campaigns/${id}`,
  sendTestEmail: "api/send-test-mail/",
  patchCampaign: (id) => `api/mail-campaigns/${id}/`,
  sendAllEmails: (id) => `api/send-mail-campaign/${id}/`,
  delCamp: (id) => `api/send-mail-campaign/${id}/`,
  campaigns: (data) => {
    // Start with the required parameter
    let url = `api/mail-campaigns/`;

    // Only add parameters that have true values or non-null values
    if (data.start_date !== null && data.end_date !== null)
      url += `?start_date=${data.start_date} &end_date=${data.end_date}`;

    return url;
  },
};
