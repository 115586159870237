import { createVuetify } from "vuetify";
import * as components from "vuetify/lib/components";
import "vue-material-design-icons/styles.css";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";
import { VDataTable } from "vuetify/labs/VDataTable";

import { VFileUpload } from "vuetify/labs/VFileUpload";

export default createVuetify({
  components: {
    ...components,
    VDataTable,
    VFileUpload,
  },
  defaults: {
    VFileUpload: {
      title: "Upload Files", // Set a default title
      // divider: false, // Enable or disable the divider
    },
  },
  themes: {
    light: {
      primary: "#3f51b5", // set your primary color
      secondary: "#b0bec5", // set your secondary color
    },
  },
  // icons: {
  //   iconfont: "md, mdi",
  // },
  icons: {
    defaultSet: 'mdi', // This sets the default icon set to MDI
  },
});
