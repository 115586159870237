<template>
  <div class="sub-container">
    <span class="mt-n3 ml-6 head-cam-text">
      {{ routeParams ? "Edit" : "Add" }} Campaign</span
    >

    <v-tabs v-model="tab" align-tabs="center" color="deep-purple-accent-4">
      <v-tab :value="1">Details</v-tab>
      <v-tab :value="2" :disabled="formData.subject === ''">Delivery</v-tab>
      <v-tab :value="3" :disabled="formData.subject === ''">Audience</v-tab>
      <v-tab :value="4" :disabled="formData.subject === ''">Content</v-tab>
      <v-tab :value="5" :disabled="formData.subject === ''">Confirmation</v-tab>
    </v-tabs>

    <v-card variant="flat" class="mx-6 mt-6" v-if="tab === 1">
      <v-card-text>
        <v-form ref="subForm" v-model="isValid">
          <v-row class="mt-n6"
            ><v-col class="mx-3 mt-3" :cols="12" :md="2" :sm="3"
              >Campaign Name</v-col
            ><v-col :cols="12" :md="6" :sm="3" align-self="start"
              ><v-text-field
                class="mt-2 mx-auto"
                ref="fname"
                :rules="rules.required"
                v-model="formData.name"
                density="compact"
                style="color: #3c3731"
                variant="outlined" /></v-col
          ></v-row>
          <v-row class="mt-n6"
            ><v-col class="mx-3" :cols="12" :md="2" :sm="3">Subject</v-col
            ><v-col :cols="12" :md="6" :sm="3" align-self="start"
              ><v-text-field
                class="mt-2 mx-auto"
                ref="lname"
                :rules="rules.required"
                v-model="formData.subject"
                density="compact"
                style="color: #3c3731"
                variant="outlined" /></v-col
          ></v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="ml-6">
        <v-btn
          @click="routeParams ? changeTab(2) : save()"
          color="#50568e"
          width="160"
          variant="flat"
          class="text-none"
        >
          <span class="btn-text" v-if="!loadingState">Save & next</span>
          <v-progress-circular
            :width="3"
            color="#50568e"
            indeterminate
            v-if="loadingState"
          ></v-progress-circular>
        </v-btn>

        <v-btn
          color="#343a40"
          width="80"
          variant="flat"
          :to="{ name: 'CampaignList' }"
        >
          <span class="btn-text">Cancel</span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card variant="flat" class="mx-6 mt-6" v-if="tab === 2">
      <v-card-text>
        <v-form ref="timeForm" v-model="timeValid">
          <v-row class="mt-n6"
            ><v-col class="mx-3 mt-3" :cols="12" :md="2" :sm="3"
              >Time Period</v-col
            ><v-col :cols="12" :md="6" :sm="3" align-self="start">
              <v-select
                label="Select"
                :items="[
                  'immediately',
                  'once',
                  'daily',
                  'weekly',
                  'monthly',
                  'yearly',
                ]"
                density="compact"
                :rules="rules.required"
                style="color: #3c3731"
                variant="outlined"
                v-model="formData.frequency"
              ></v-select> </v-col
          ></v-row>

          <v-row class="mt-n6" v-if="formData.frequency !== 'immediately'"
            ><v-col class="mx-3 mt-3" :cols="12" :md="2" :sm="3"
              >start date</v-col
            ><v-col :cols="12" :md="6" :sm="3" align-self="start">
              <input
                required
                type="date"
                class="date-field"
                v-model="formData.start_date"
              /> </v-col
          ></v-row>
          <v-row
            class="mt-0"
            v-if="
              formData.frequency !== 'immediately' &&
              formData.frequency !== 'once'
            "
            ><v-col class="mx-3 mt-3" :cols="12" :md="2" :sm="3">End date</v-col
            ><v-col :cols="12" :md="6" :sm="3" align-self="start">
              <input
                required
                type="date"
                class="date-field"
                v-model="formData.end_date"
              /> </v-col
          ></v-row>
          <v-row class="mt-0" v-if="formData.frequency !== 'immediately'"
            ><v-col class="mx-3 mt-3" :cols="12" :md="2" :sm="3">Time</v-col
            ><v-col :cols="12" :md="6" :sm="3" align-self="start">
              <input
                required
                type="time"
                class="date-field"
                v-model="formData.start_time"
              /> </v-col
          ></v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="ml-6">
        <v-btn
          @click="
            formData.frequency === 'immediately' ? saveTime() : saveTimeFreq()
          "
          class="text-none"
          color="#50568e"
          width="160"
          variant="flat"
        >
          <span class="btn-text" v-if="!loadingState">Save Time & Next</span>
          <v-progress-circular
            :width="3"
            color="#50568e"
            indeterminate
            v-if="loadingState"
          ></v-progress-circular>
        </v-btn>

        <v-btn
          color="#343a40"
          width="80"
          variant="flat"
          :to="{ name: 'CampaignList' }"
        >
          <span class="btn-text">Cancel</span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card variant="flat" class="mx-6 mt-6" v-if="tab === 3">
      <v-alert
        text="Choose a list of subscribers form list. You can select subscribers manually to add there emails to send email to the specific subscribers. There is no requirement to select all of the options, we just provide to create your desired audience as you want.
"
        type="info"
      ></v-alert>
      <v-card-text class="mt-8">
        <v-form ref="audienceForm" v-model="audienceValid">
          <v-row class="mt-n6"
            ><v-col class="mx-3" :cols="12" :md="2" :sm="3">List(s)</v-col
            ><v-col :cols="12" :md="6" :sm="3" align-self="start">
              <v-autocomplete
                v-model="formData.group_ids"
                :items="lists.length > 0 ? lists : []"
                color="blue-grey-lighten-2"
                item-title="name"
                item-value="id"
                label="Select"
                chips
                closable-chips
                multiple
                density="compact"
                style="color: #3c3731"
                variant="outlined"
              ></v-autocomplete></v-col
          ></v-row>
          <!--<v-row class="mt-n6"
            ><v-col class="mx-3" :cols="12" :md="2" :sm="3"
              >Select subscriber</v-col
            ><v-col :cols="12" :md="6" :sm="3" align-self="start">
              <v-autocomplete
                v-model="allSubscribers"
                :items="subscribers.length > 0 ? subscribers : []"
                color="blue-grey-lighten-2"
                label="Select"
                :item-title="formattedName"
                item-value="id"
                chips
                closable-chips
                multiple
                density="compact"
                style="color: #3c3731"
                variant="outlined"
              >
                <template #item="{ item }">
                  <v-list-item :key="item.raw.id">
                    <v-list-item-title class="mt-n3">
                      <v-checkbox
                        v-model="test"
                        :value="item.raw"
                        :label="`${item.raw.first_name} ${item.raw.last_name}`"
                      ></v-checkbox>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete> </v-col
          ></v-row>-->
        </v-form>
      </v-card-text>
      <v-card-actions class="ml-6">
        <v-btn
          @click="saveAudience()"
          class="text-none"
          color="#50568e"
          variant="flat"
        >
          <span class="btn-text" v-if="!loadingState"
            >Save Audience & Next</span
          >
          <v-progress-circular
            :width="3"
            color="#50568e"
            indeterminate
            v-if="loadingState"
          ></v-progress-circular>
        </v-btn>

        <v-btn
          color="#343a40"
          width="80"
          variant="flat"
          :to="{ name: 'CampaignList' }"
        >
          <span class="btn-text">Cancel</span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card variant="flat" class="mx-6 mt-6" v-if="tab === 4">
      <v-card-actions class="ml-6">
        <!-- <v-btn
          @click="loadTemplatesPage()"
          color="#50568e"
          width="160"
          variant="flat"
          class="text-none mt-6"
        >
          <span class="btn-text" v-if="!loadingState">Choose Template</span>
          <v-progress-circular
            :width="3"
            color="#50568e"
            indeterminate
            v-if="loadingState"
          ></v-progress-circular>
        </v-btn> -->
        <v-row class="mt-0"
          ><v-col class="mx-3" :cols="12" :md="2" :sm="3">Choose Template</v-col
          ><v-col :cols="12" :md="6" :sm="3" align-self="start">
            <v-autocomplete
              v-model="templateChosen"
              :items="templates.length > 0 ? templates : []"
              color="blue-grey-lighten-2"
              item-title="name"
              item-value="id"
              label="Select"
              chips
              closable-chips
              density="compact"
              style="color: #3c3731"
              variant="outlined"
            ></v-autocomplete></v-col
        ></v-row>
      </v-card-actions>
      <v-divider class="mt-2" />

      <v-card-text class="mt-8">
        <v-form ref="timeForm" v-model="tempValid">
          <QuillEditor
            v-if="TemplateBody === ''"
            theme="snow"
            class="quill-editor"
            v-model:content="formData.body"
            contentType="html"
          />
          <div
            v-if="TemplateBody !== ''"
            v-html="TemplateBody"
            class="temp-body"
          ></div>
          <div >
            <v-card-text v-if="alluploadedFiles.length > 0">
              <span class="dash-font-style">Previously Uploaded files</span>
              <div
                v-if="alluploadedFiles.length > 0"
                class="d-flex flex-wrap gap-2"
              >
                <v-chip
                  v-for="(file, index) in alluploadedFiles"
                  :key="index"
                  color="green"
                  variant="flat"
                  size="large"
                  closable
                  @click="openFilePreview(file)"
                  @click:close="removeFile(index)"
                >
                  {{ file.name }}
                </v-chip>
              </div>
            </v-card-text>
          </div>
          <div>
            <v-dialog v-model="previewDialog" max-width="">
              <v-card v-if="selectedFile">
                <v-card-title>
                  File Preview: {{ selectedFile.name }}
                  <v-spacer></v-spacer>
                  <!-- <v-btn icon @click="previewDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn> -->
                </v-card-title>

                <v-card-text class="preview-content">
                  <template v-if="isPreviewable(selectedFile)">
                    <!-- <iframe
                    v-if="getFilePreviewUrl(selectedFile)"
                    :src="getFilePreviewUrl(selectedFile)"
                    width="100%"
                    height="1000px"
                    frameborder="0"
                  ></iframe> -->

                    <embed
                      v-if="getFilePreviewUrl(selectedFile)"
                      :src="getFilePreviewUrl(selectedFile)"
                      width="100%"
                      height="700px"
                    />

                    <v-alert v-else type="info" class="mt-4">
                      Preview not available for this file type.
                    </v-alert>
                  </template>
                  <v-alert v-else type="info" class="mt-4">
                    Unable to preview this file type.
                  </v-alert>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <!-- <v-btn color="primary" @click="downloadFile(selectedFile)">
                  Download
                </v-btn> -->

                  <v-btn
                    class="text-white flex-grow-1 text-none"
                    color="error"
                    prepend-icon="mdi-close"
                    rounded="0"
                    variant="flat"
                    @click="previewDialog = false"
                  >
                    close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="ml-6">
        <v-btn
          @click="saveContent()"
          color="#50568e"
          class="text-none"
          variant="flat"
        >
          <span class="btn-text" v-if="!loadingState">Save Content & next</span>
          <v-progress-circular
            :width="3"
            color="#50568e"
            indeterminate
            v-if="loadingState"
          ></v-progress-circular>
        </v-btn>

        <v-btn
          color="#343a40"
          width="80"
          variant="flat"
          :to="{ name: 'CampaignList' }"
        >
          <span class="btn-text">Cancel</span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card variant="flat" class="mx-6 mt-6 content-card" v-if="tab === 5">
      <v-card-text class="mt-8">
        <v-row>
          <v-col>
            <h5>Details</h5>
            <br /><span>{{
              this.formData.name ? this.formData.name : "N/A"
            }}</span>
          </v-col>
          <v-col>
            <h5>Subject</h5>
            <br /><span>{{
              this.formData.subject ? this.formData.subject : "N/A"
            }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h5>Frequency</h5>
            <br /><span>{{
              this.formData.frequency ? this.formData.frequency : "N/A"
            }}</span>
          </v-col>
          <v-col>
            <h5>Start at</h5>
            <br /><span>{{
              this.formData.start_date ? this.formData.start_date : "N/A"
            }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h5>End at</h5>
            <br /><span>{{
              this.formData.end_date ? this.formData.end_date : "N/A"
            }}</span>
          </v-col>
          <v-col>
            <h5>Time</h5>
            <br /><span>{{
              this.formData.start_time ? this.formData.start_time : "N/A"
            }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h5>From List</h5>
            <br /><span>{{
              this.formData.group_ids ? this.formData.group_ids.length : "N/A"
            }}</span>
          </v-col>
          <v-col>
            <h5>Subscribers</h5>
            <br /><span>{{
              this.formData.recipient_ids
                ? this.formData.recipient_ids.length
                : "N/A"
            }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <h5>Content</h5>
            <br /><span>
              <v-card max-width="344" max-height="344" variant="flat">
                <div
                  v-html="formData.body"
                  v-if="templateChosen === null"
                ></div>
                <div v-html="TemplateBody" v-if="templateChosen !== null"></div>
              </v-card>
            </span>
          </v-col>
          <v-col>
            <!-- <h5>Details</h5>
            <br /><span>test</span> -->
          </v-col>
        </v-row>
        <v-form ref="timeForm" v-model="tempValid" class="mt-6">
          <h4>Want to test ?</h4>
          <v-row class="mt-0 mx-6"
            ><v-text-field
              class="mt-2 mx-auto"
              ref="fname"
              :rules="rules.required"
              v-model="formData.test_email"
              density="compact"
              style="color: #3c3731"
              variant="outlined"
          /></v-row>

          <v-btn
            @click="sendTestEmail()"
            color="green"
            class="text-none ml-6"
            variant="flat"
          >
            <span class="btn-text" v-if="!loadingState">Send Test Mail</span>
            <v-progress-circular
              :width="3"
              color="#50568e"
              indeterminate
              v-if="loadingState"
            ></v-progress-circular>
          </v-btn>
        </v-form>
      </v-card-text>
      <v-card-actions class="ml-6">
        <v-btn
          @click="sendAllEmail()"
          color="#50568e"
          class="text-none"
          variant="flat"
        >
          <span class="btn-text" v-if="!loadingState">Send All Emails</span>
          <v-progress-circular
            :width="3"
            color="#50568e"
            indeterminate
            v-if="loadingState"
          ></v-progress-circular>
        </v-btn>

        <v-btn
          color="#343a40"
          width="80"
          variant="flat"
          :to="{ name: 'CampaignList' }"
        >
          <span class="btn-text">Cancel</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>

  <v-dialog v-model="loaderState" max-width="500">
    <v-card>
      <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
      <v-card-actions
        ><v-spacer />
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>

        <v-spacer />
      </v-card-actions>

      <v-progress-linear
        class="mx-2 mt-n3"
        color="teal"
        indeterminate
      ></v-progress-linear>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="templateDialog" max-width="500">
    <v-card> </v-card>
  </v-dialog>
</template>

<script>
import eventBus from "@/utils/eventBus";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "addCamapign",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("subscribers/getLists");
      // v.$store.dispatch("subscribers/getSubscribers");
      v.$store.dispatch("templates/getTemplates");
      if (v.$route.params.id !== "") {
        v.$store.dispatch(
          "campaign/getSingleCampaign",
          parseInt(v.$route.params.id)
        );
      }
    });
  },

  components: { QuillEditor },

  data() {
    return {
      alluploadedFiles: [],
      selectedFile: null,
      previewDialog: false,
      TemplateBody: "",
      templateChosen: null,
      templateDialog: false,
      modelname: "",
      tempValid: false,
      audienceValid: false,
      tab: null,
      timeValid: false,
      isValid: false,
      allSubscribers: [],
      loadingState: false,
      screenSize: "",
      oldValue: false,
      test: [],
      formData: {
        name: "",
        recipient_ids: [],
        group_ids: [],
        subject: "",
        body: "",
        frequency: "immediately",
        start_date: "",
        end_date: "",
        start_time: null,
        attachments: [],
      },

      rules: {
        required: [(value) => !!value || "Required."],
        email: [
          (value) => !!value || "Required.",
          (value) => /.+@.+\..+/.test(value) || "Invalid email address.",
        ],
        password: [(value) => !!value || "Required."],
      },
    };
  },

  computed: {
    templates() {
      return this.$store.getters["templates/templatesGetters"]("Templates");
    },
    formattedName() {
      return (item) => `${item.first_name} ${item.last_name}`;
    },
    lists() {
      return this.$store.getters["subscribers/subscribersGetters"]("Lists");
    },
    loaderState() {
      return this.$store.getters["campaign/campaignGetters"]("loaderState");
    },
    subscribers() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "subscribers"
      );
    },

    tabState() {
      return this.$store.getters["campaign/campaignGetters"]("tabState");
    },
    SingleCampaign() {
      return this.$store.getters["campaign/campaignGetters"]("campaign");
    },
    routeParams() {
      return !!this.$route.params.id;
    },

    listEdited() {
      return this.lists
        ? this.lists
            .filter((f) => f.id === parseInt(this.$route.params.id))
            .shift()
        : {};
    },

    singleTemplate() {
      return this.$store.getters["templates/templatesGetters"]("template");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    eventBus.on("campaignSaved", (id) => {
      this.$router.push({ name: "AddCampaign", params: { id: id } });
    });
    eventBus.on("emailSent", () => {
      this.$router.push({ name: "CampaignList" });
    });
  },
  methods: {
    removeFile(index) {
      this.newfiles.splice(index, 1);
    },
    openFilePreview(file) {
      this.selectedFile = file;
      this.previewDialog = true;
    },
    isPreviewable(file) {
      // Define previewable file types
      const previewableTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "image/gif",
        "text/plain",
      ];

      // If file.type is undefined, try to infer it from the file URL
      if (!file.type && file.url) {
        const extension = file.url.split(".").pop().toLowerCase();

        const extensionToMimeType = {
          pdf: "application/pdf",
          jpg: "image/jpeg",
          jpeg: "image/jpeg",
          png: "image/png",
          gif: "image/gif",
          txt: "text/plain",
        };

        file.type = extensionToMimeType[extension] || "";
      }

      return previewableTypes.includes(file.type);
    },

    getFilePreviewUrl(file) {
      // Ensure file is a valid Blob or File before creating object URL
      if (file instanceof Blob || file instanceof File) {
        if (
          file.type === "application/pdf" ||
          ["image/jpeg", "image/png", "image/gif"].includes(file.type)
        ) {
          return URL.createObjectURL(file);
        }
      }

      // Return URL directly if it's already a URL
      if (file.url) {
        return encodeURI(file.url); // Ensures spaces and special characters are properly formatted
      }

      return null;
    },

    formatDate(val) {
      const selectedDate = new Date(val);
      const year = selectedDate.getFullYear();
      const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
      const day = String(selectedDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    },
    getDateToday() {
      // Get today's date
      let today = new Date();

      // Extract year, month, and day
      let year = today.getFullYear().toString().slice(-4); // Extract last two digits of the year
      let month = ("0" + (today.getMonth() + 1)).slice(-2); // Month is 0-indexed, so add 1
      let day = ("0" + today.getDate()).slice(-2);

      // Concatenate year, month, and day with dashes
      let formattedDate = year + "-" + month + "-" + day;

      return formattedDate;
    },
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    save() {
      if (!this.isValid) {
        this.$refs.subForm.validate();
      } else {
        // this.formData.recipient_ids = [...this.test];
        this.$store.dispatch("campaign/saveCampaign", this.formData);
      }
    },
    saveTime() {
      if (this.formData.frequency === "") {
        eventBus.emit("showToast", {
          type: "error",
          message: "All fields required",
        });
      } else {
        this.timeValid = true;
        this.tab = 3;
      }
    },

    saveTimeFreq() {
      if (
        this.formData.frequency === "" ||
        this.formData.start_date === "" ||
        this.formData.start_time === null ||
        this.formData.start_time === ""
      ) {
        eventBus.emit("showToast", {
          type: "error",
          message: "All fields required",
        });
      } else {
        this.timeValid = true;
        this.tab = 3;
      }
    },

    saveAudience() {
      if (
        this.formData.recipient_ids === "" ||
        this.formData.group_ids === ""
      ) {
        eventBus.emit("showToast", {
          type: "error",
          message: "All fields required",
        });
      } else {
        this.tab = 4;
      }
    },
    saveContent() {
      this.tab = 5;
    },

    changeTab(val) {
      this.tab = val;
    },
    sendTestEmail() {
      if (this.formData.test_email === "") {
        eventBus.emit("showToast", {
          type: "error",
          message: "Enter test Email",
        });
      } else {
        if (this.TemplateBody !== "") {
          this.formData.body = this.TemplateBody;
        }
        if (this.singleTemplate.attachments) {
          this.formData.attachments = [...this.singleTemplate.attachments];
        }
        delete this.formData.groups;

        const data = {
          allData: { ...this.formData },
          id: this.$route.params.id,
        };

        console.log(data.allData)

        this.$store.dispatch("campaign/editCampaign", data);
      }
    },
    sendAllEmail() {
      delete this.formData.groups;
      if (this.TemplateBody !== "") {
        this.formData.body = this.TemplateBody;
      }
      if (this.singleTemplate.attachments) {
        this.formData.attachments = [...this.singleTemplate.attachments];
      }
      const data = {
        allData: { ...this.formData },
        id: parseInt(this.$route.params.id),
      };
      this.$store.dispatch("campaign/editFullCampaign", data);
    },

    edit() {
      if (!this.isValid) {
        this.$refs.subForm.validate();
      } else {
        this.formData.subscribers = [...this.test];
        this.$store.dispatch("list/editList", this.formData);
      }
    },

    getObject(val) {
      console.log(val);
      return val;
    },
    loadTemplatesPage() {
      this.templateDialog = true;
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
    listEdited: {
      handler: function () {
        if (this.listEdited !== "undefined") {
          this.formData = { ...this.listEdited };
        }
      },
    },
    test: {
      handler: function () {
        const subscriberIds = this.test.map((subscriber) => subscriber.id);
        this.allSubscribers = [...subscriberIds];
        this.formData.recipient_ids = [...subscriberIds];
      },
      immediate: true,
    },
    tabState: {
      handler: function () {
        this.tab = this.tabState;
      },
    },
    routeParams: {
      handler: function () {
        if (this.$route.params.id !== "") {
          this.$store.dispatch(
            "campaign/getSingleCampaign",
            parseInt(this.$route.params.id)
          );
        } else {
          const today = new Date();
          this.formData.start_date = today.toISOString().split("T")[0];
          this.formData.end_date = today.toISOString().split("T")[0];
        }
      },
      immediate: true,
    },
    SingleCampaign: {
      handler: function () {
        if (this.SingleCampaign !== "undefined") {
          this.formData = { ...this.SingleCampaign };
          this.formData.group_ids = this.SingleCampaign.groups;
          this.allSubscribers = this.SingleCampaign.recipients;
          this.formData.start_date = this.formatDate(
            this.SingleCampaign.start_date
          );
          this.formData.end_date = this.formatDate(
            this.SingleCampaign.end_date
          );
          if (
            this.SingleCampaign.start_date === null ||
            this.SingleCampaign.end_date === null
          ) {
            this.formData.end_date = this.getDateToday();
            this.formData.start_date = this.getDateToday();
          }
          if (this.SingleCampaign.body.startsWith("<!DOCTYPE HTML PUBLIC")) {
            this.TemplateBody = this.SingleCampaign.body;
            // this.templateChosen = 0
          }
          if (this.SingleCampaign.body.startsWith("<!DOCTYPE HTML PUBLIC")) {
            this.TemplateBody = this.SingleCampaign.body;
            // this.templateChosen = 0
          }
        }
      },
      deep: true,
    },
    allSubscribers: {
      handler: function () {
        if (this.allSubscribers.length > 0)
          this.formData.recipient_ids = [...this.allSubscribers];
      },
      immediate: true,
    },
    formData: {
      handler: function () {
        if (this.formData.frequency === "immediately")
          this.formData.start_date = null;
        this.formData.end_date = null;
      },
      immediate: true,
    },
    templateChosen: {
      handler: function () {
        if (this.templateChosen !== null) {
          this.$store.dispatch(
            "templates/getSingleTemplate",
            parseInt(this.templateChosen)
          );
        }
      },
      immediate: true,
    },

    singleTemplate: {
      handler: function () {
        if (this.singleTemplate !== "undefined") {
          this.TemplateBody = this.singleTemplate.content;

          if (this.singleTemplate.attachments) {
            const updatedAttachments = this.singleTemplate.attachments.map(
              (att) => ({
                ...att,
                url: `http://173.249.26.131/${att.url}`,
              })
            );

            this.alluploadedFiles = [...updatedAttachments];
          }

          // this.formData.body = this.singleTemplate.content
        }
      },
    },
  },
};
</script>

<style>
@import url("../campaignStyle.css");
</style>
