<template>
  <div class="sub-container">
    <v-card-actions>
      <span class="mt-n3 ml-6 head-sub-text">All Emails</span>
      <v-spacer />
    </v-card-actions>

    <v-card-actions class="">
      <div class="search-field px-2">
        <p>Campaign</p>

        <v-autocomplete
          v-model="formData.mail_campaign_id"
          :items="campaigns.length > 0 ? campaigns : []"
          class="mx-2 mt-3"
          color="blue-grey-lighten-2"
          item-title="subject"
          item-value="id"
          label="Select"
          chips
          closable-chips
          density="compact"
          style="color: #3c3731"
          variant="outlined"
        ></v-autocomplete>
      </div>
      <div class="search-field px-2">
        <p class="mt-n2">Recipient</p>

        <v-text-field
          class="mx-2 my-2"
          v-model="formData.recipients"
          label="Search"
          variant="outlined"
          density="compact"
          hide-details
          style="color: #3c3731"
        />
        <!-- <v-text-field
          v-model="search"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details
          single-line
        /> -->
      </div>
      <div class="d-flex justify-space-between">
        <!-- <p>Sent</p> -->
        <div class="px-3">
          <v-chip
            size="small"
            :color="showSent ? 'green' : ''"
            :prepend-icon="showSent ? 'mdi-check' : ''"
            @click="addFilter('sent')"
          >
            Sent
          </v-chip>
        </div>

        <div class="px-2">
          <v-chip
            size="small"
            :color="showOp ? 'green' : ''"
            :prepend-icon="showOp ? 'mdi-check' : ''"
            @click="addFilter('opened')"
          >
            Opened
          </v-chip>
        </div>
        <div class="px-2">
          <v-chip
            size="small"
            :color="showDel ? 'green' : ''"
            :prepend-icon="showDel ? 'mdi-check' : ''"
            @click="addFilter('delivered')"
          >
            Delivered
          </v-chip>
        </div>
        <div class="px-2">
          <v-chip
            size="small"
            :color="showclicked ? 'green' : ''"
            :prepend-icon="showclicked ? 'mdi-check' : ''"
            @click="addFilter('clicked')"
          >
            Clicked
          </v-chip>
        </div>
      </div>
      <v-spacer />

      <v-btn
        color="red"
        variant="flat"
        class="text-none"
        size="small"
        @click="submitFilters"
      >
        <span class="btn-text">Search</span>
      </v-btn>
      <v-spacer />
    </v-card-actions>

    <v-card variant="flat" class="ml-3">
      <v-data-table
        :items="emails.length > 0 ? emails : []"
        :headers="headers"
        item-value="id"
        :search="search"
        :hide-default-footer="false"
      >
        <template #[`item.campaign_name`]="{ item }">
          {{
            item.selectable.campaign_name !== undefined
              ? item.selectable.campaign_name
              : "Test "
          }}
        </template>

        <template #[`item.is_sent`]="{ item }">
          <v-chip
            :color="getColor(item.selectable.is_sent)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_sent ? "sent" : "not sent"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.is_clicked`]="{ item }">
          <v-chip
            :color="getColor(item.selectable.is_clicked)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_clicked ? "clicked" : "not clicked"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.is_opened`]="{ item }">
          <v-chip
            :color="getColor(item.selectable.is_opened)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_opened ? "opened" : "unopened"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.is_delivered`]="{ item }">
          <v-chip
            :color="getColor(item.selectable.is_delivered)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_delivered ? "delivered" : "undelivered"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props"> mdi-dots-vertical</v-icon>
            </template>

            <v-list>
              <v-list-item
                class="mt-n3"
                :to="{ name: 'AddList', params: { id: item.selectable.id } }"
              >
                <v-list-item-title class="list-title">Edit</v-list-item-title>
              </v-list-item>

              <v-list-item
                class="mt-n3"
                @click="deleteItem(item.selectable.id)"
              >
                <v-list-item-title class="list-title">Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-card-actions class="mt-n12">
        <v-spacer class="" />

        <v-btn
          color="#38a4f8"
          variant="flat"
          class="text-none"
          size="small"
          @click="onPaginationNextChange"
        >
          <span class="btn-text">Prev</span>
        </v-btn>
        <div class="ml-n4"></div>

        <v-btn
          color="green"
          variant="flat"
          class="text-none"
          size="small"
          @click="onPaginationChange"
        >
          <span class="btn-text">Next</span>
        </v-btn>
        <div class="ml-12"></div>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="loaderState" max-width="500">
      <v-card>
        <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
        <v-card-actions
          ><v-spacer />
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>

          <v-spacer />
        </v-card-actions>

        <v-progress-linear
          class="mx-2 mt-n3"
          color="teal"
          indeterminate
        ></v-progress-linear>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ListDisplayPage",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("email/getPaginatedEmails", 0);
      v.$store.dispatch("campaign/getCampaigns", v.campData);
    });
  },

  data() {
    return {
      showSent: false,
      showOp: false,
      showDel: false,
      showclicked: false,
      offset: 0,
      dialog: true,
      groups: [],
      menu: false,
      menu2: false,
      menu3: false,
      search: "",
      isValid: false,
      loadingState: false,
      screenSize: "",
      oldValue: false,
      campData:{
        start_date: null,
        end_date: null,
      },
      formData: {
        is_sent: false,
        is_clicked: false,
        is_delivered: false,
        is_opened: false,
        to_email: null,
        mail_campaign_id: null,
        page: null,
      },

      selected: [],

      headers: [
        {
          title: "Campaign",
          key: "campaign_name",
        },
        { title: "Recipient", key: "to_email" },

        {
          title: "Sent",
          key: "is_sent",
        },
        {
          title: "Opened",
          key: "is_opened",
        },
        {
          title: "Delieverd",
          key: "is_delivered",
        },
        {
          title: "Clicked",
          key: "is_clicked",
        },
        // {
        //     title: "No of Subscribers",
        //     key: "status",

        // },
        // { title: "Actions", key: "actions" },
      ],
    };
  },

  computed: {
    emails() {
      return this.$store.getters["email/emailGetters"]("emails");
    },
    subscribers() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "subscribers"
      );
    },
    loaderState() {
      return this.$store.getters["email/emailGetters"]("loaderState");
    },
    campaigns() {
      return this.$store.getters["campaign/campaignGetters"]("campaigns");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    addFilter(filterType) {
      // Toggle the show state for the selected filter
      if (filterType === "sent") {
        this.showSent = !this.showSent;
        this.formData.is_sent = this.showSent; // Set to the current toggle state
      } else if (filterType === "opened") {
        this.showOp = !this.showOp;
        this.formData.is_opened = this.showOp; // Set to the current toggle state
      } else if (filterType === "delivered") {
        this.showDel = !this.showDel;
        this.formData.is_delivered = this.showDel; // Set to the current toggle state
      } else if (filterType === "clicked") {
        this.showclicked = !this.showclicked;
        this.formData.is_clicked = this.showclicked; // Set to the current toggle state
      }
    },

    submitFilters() {
      // Reset page to 1
      this.formData.page = 1;

      // Create a clean object with only the necessary fields
      const cleanData = {
        mail_campaign_id: this.formData.mail_campaign_id,
      };

      // Only add true boolean values
      if (this.formData.is_sent) cleanData.is_sent = true;
      if (this.formData.is_clicked) cleanData.is_clicked = true;
      if (this.formData.is_delivered) cleanData.is_delivered = true;
      if (this.formData.is_opened) cleanData.is_opened = true;

      // Only add non-null values
      if (this.formData.to_email) cleanData.to_email = this.formData.to_email;
      if (this.formData.page) cleanData.page = this.formData.page;

      // Dispatch the cleaned data
      this.$store.dispatch("email/getFilteredEmails", cleanData);
    },
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    deleteItem(val) {
      alert("Are you sure you want to delete list ?");
      this.$store.dispatch("list/deleteList", parseInt(val));
    },

    getColor(val) {
      if (val) return "#46c35f";
      else return "orange";
    },
    changeAvatarColor(item) {
      // Change the color of the clicked avatar
      item.avatarProps.color = "red"; // Change to desired color
    },

    onPaginationChange() {
      // Update the offset state

      if (
        this.formData.to_email !== null ||
        this.formData.mail_campaign_id !== null ||
        this.formData.is_clicked ||
        this.formData.is_delivered ||
        this.formData.is_opened ||
        this.formData.is_sent
      ) {
        this.formData.page += 1;
        this.$store.dispatch("email/getFilteredEmails", this.formData);
      } else {
        this.offset += 10;
        this.$store.dispatch("email/getPaginatedEmails", this.offset);
      }
    },

    onPaginationNextChange() {
      // Update the offset state

      if (
        this.formData.to_email !== null ||
        this.formData.mail_campaign_id !== null ||
        this.formData.is_clicked ||
        this.formData.is_delivered ||
        this.formData.is_opened ||
        this.formData.is_sent
      ) {
        this.formData.page -= 1;
        this.$store.dispatch("email/getFilteredEmails", this.formData);
      } else {
        this.offset -= 10;
        this.$store.dispatch("email/getPaginatedEmails", this.offset);
      }
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style>
@import url("../emailStyle.css");
</style>
