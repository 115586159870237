import call from "@/service/http";
import constants from "./subscribersConstants";
import eventBus from "@/utils/eventBus";

const subscribersModule = {
  namespaced: true,
  state: {
    Lists: [],
    loaderState: false,
    subscribers: [],
    blacklistedSubs: [],
    filteredSubs: [],
    singleSub: {},
    limit: null,
    next: null,
    previous: null,
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
    NEWMUTATE(state, payload) {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
  getters: {
    subscribersGetters: (state) => (val) => state[val],
  },
  actions: {
    getSubscribers: ({ commit }) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.saveSub)
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", {
              state: "subscribers",
              value: res.data.data,
            });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Error fetching Subscribers",
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },
    getPaginatedSubscribers: ({ commit }) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.pagSub)
        .then((res) => {
          if (res.status === 200) {
            commit("NEWMUTATE", {
              subscribers: res.data.results,
              next: res.data.next,
              previous: res.data.previous,
              limit: res.data.limit,
            });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Error fetching Subscribers",
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },
    // getNewPaginatedSubscribers: ({ commit }, data) => {
    //   commit("MUTATE", { state: "loaderState", value: true });
    //   call("get", constants.test(data))
    //     .then((res) => {
    //       // console.log(res);
    //       if (res.data.status_code === 200) {
    //         commit("NEWMUTATE", {
    //           subscribers: res.data.data.results,
    //           next: res.data.data.next,
    //           previous: res.data.data.previous,
    //           limit: res.data.data.limit,
    //         });
    //       } else {
    //         eventBus.emit("showToast", {
    //           type: "error",
    //           message: "Error fetching Subscribers",
    //         });
    //       }
    //       commit("MUTATE", { state: "loaderState", value: false });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       commit("MUTATE", { state: "loaderState", value: false });
    //     });
    // },
    moreSubs: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", data)
        .then((res) => {
          if (res.status === 200) {
            commit("NEWMUTATE", {
              subscribers: res.data.results,
              next: res.data.next,
              previous: res.data.previous,
              limit: res.data.limit,
            });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Error fetching Subscribers",
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },

    getListSubscribers: ({ commit, dispatch }) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.saveSub)
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", {
              state: "subscribers",
              value: res.data.data,
            });
            dispatch("getLists");
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Error fetching Subscribers",
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },
    getLists: ({ commit }) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.subscribers)
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", { state: "Lists", value: res.data.data });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Error fetching Lists",
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },

    saveSubscriber: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("post", constants.saveSub, data)
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });
            eventBus.emit("subAdded");
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Failed to add subscriber",
            });
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
    editSubscriber: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("patch", constants.delSub(data.id), data.alldata)
        .then((res) => {
          if (res.status === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: "Edited Successfully",
            });

            // eventBus.emit("subAdded");
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Failed to edit subscriber",
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
    deleteSubscriber: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("delete", constants.delSub(data))
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });
            window.location.reload();
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Failed to Delete subscriber",
            });
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
    unsubSubscriber: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("patch", constants.delSub(data.id), data.subdata)
        .then((res) => {
          if (res.status === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: "Success",
            });
            commit("MUTATE", { state: "loaderState", value: false });
            window.location.reload();
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Failed to Delete subscriber",
            });
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
    getBlackListedSubscribers: ({ commit }) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.blackSub)
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", {
              state: "blacklistedSubs",
              value: res.data.data,
            });
            commit("MUTATE", { state: "loaderState", value: false });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Error fetching Subscribers",
            });
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },

    // getSubscribeTrue: ({ commit }) => {
    //   commit("MUTATE", { state: "loaderState", value: true });
    //   call("get", constants.subTrue)
    //     .then((res) => {
    //       if (res.data.status_code === 200) {
    //         commit("MUTATE", {
    //           state: "filteredSubs",
    //           value: res.data.data,
    //         });
    //       } else {
    //         eventBus.emit("showToast", {
    //           type: "error",
    //           message: "Error fetching Subscribers",
    //         });
    //       }
    //       commit("MUTATE", { state: "loaderState", value: false });
    //     })
    //     .catch((err) => {
    //       commit("MUTATE", { state: "loaderState", value: false });
    //       console.log(err);
    //     });
    // },
    getSubscribeFalse: ({ commit }) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.subFalse)
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", {
              state: "filteredSubs",
              value: res.data.data,
            });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Error fetching Subscribers",
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          console.log(err);
        });
    },
    getSubscribersByID: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.subID(data))
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", {
              state: "filteredSubs",
              value: res.data.data.subscribers,
            });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Error fetching Subscribers",
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          console.log(err);
        });
    },

    deleteMultiple: ({ commit }, data) => {
      console.log(data);
      commit("MUTATE", { state: "loaderState", value: true });
      call("post", constants.deleteMultiple, data)
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message,
            });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Failed to add subscriber",
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
          window.location.reload();
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },

    getSingleSub: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.delSub(data))
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", {
              state: "singleSub",
              value: res.data.data,
            });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Error fetching Subscribers",
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          console.log(err);
        });
    },

    getFilteredSubscribers: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.filterSubs(data))
        .then((res) => {

          if (res.data.status_code === 200) {
            commit("MUTATE", { state: "subscribers", value: res.data.data.results });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },
  },
};

export default subscribersModule;
