import call from "@/service/http";
import constants from "./emailConstants";
import eventBus from "@/utils/eventBus";

const emailModule = {
  namespaced: true,
  state: {
    loaderState: false,
    singleEmails: [],
    emails: [],
    limit: null,
    next: null,
    previous: null,
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
    NEWMUTATE(state, payload) {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
  getters: {
    emailGetters: (state) => (val) => state[val],
  },
  actions: {
    getEmails: ({ commit }) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.emails)
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", { state: "emails", value: res.data.data });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Error fetching Lists",
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },
    getPaginatedEmails: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.emailsPag(data))
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.status_code === 200) {
            commit("NEWMUTATE", {
              emails: res.data.data.results,
              next: res.data.data.next,
              previous: res.data.data.previous,
           
            });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Error fetching Lists",
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },

    getSingleEmails: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.singleList(data))
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", { state: "singleEmails", value: res.data.data });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },
    getEmailsbyStatus: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });

      const url =
        data.status === "bounced"
          ? constants.status(data.id)
          : constants.deliveredStatus(data.id);
      if (url) {
        call("get", url)
          .then((res) => {
            if (res.data.status_code === 200) {
              commit("MUTATE", { state: "singleEmails", value: res.data.data });
            } else {
              eventBus.emit("showToast", {
                type: "error",
                message: res.data.message,
              });
            }
            commit("MUTATE", { state: "loaderState", value: false });
          })
          .catch((err) => {
            console.log(err);
            commit("MUTATE", { state: "loaderState", value: false });
          });
      } else {
        commit("MUTATE", { state: "loaderState", value: false });
      }
    },

    getFilteredEmails: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.filterEmails(data))
        .then((res) => {
          console.log(res)
          if (res.data.status_code === 200) {
            commit("MUTATE", { state: "emails", value: res.data.data });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },
  },
};

export default emailModule;
